import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as branchActions from '../../../actions/merge/branch';
import * as mergesActions from '../../../actions/merge/merge';
import * as buildsActions from '../../../actions/build/builds';
import {
	getProjectId,
} from '../../../selectors/router';
import {request} from '../../request';
import * as api from '../../../services/api/merge/branch';
import * as notificationsActions from '../../../actions/notifications';

function* fetchBranchList() {
	const projectId = yield select(getProjectId);

	const branchData = yield call(request, {
		entity: branchActions.Types.LOAD_DATA,
		callback: api.getBranchList,
		params: {
			projectId,
		},
	});

	const body = branchData.payload.body;

	if (body.success) {
		yield put(branchActions.loadDataReceived({
			data: body.data,
		}));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* mergeBranch({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		branch,
		message,
		resolve,
		reject,
	} = payload;

	const branchData = yield call(request, {
		entity: branchActions.Types.MERGE_BRANCH,
		callback: api.mergeBranch,
		params: {
			projectId,
			branch,
			message,
		},
	});

	const body = branchData.payload.body;

	if (body.success) {
		yield put(branchActions.mergeBranchReceived({
			data: body.data,
		}));
		yield put(mergesActions.loadData());
		yield put(buildsActions.loadData());
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* rebaseBranch({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		buildId,
		resolve,
		reject,
	} = payload;

	const branchData = yield call(request, {
		entity: branchActions.Types.REBASE_BRANCH,
		callback: api.rebaseBranch,
		params: {
			projectId,
			buildId,
		},
	});

	const body = branchData.payload.body;

	if (body.success) {
		yield put(branchActions.rebaseBranchReceived({
			data: body.data,
		}));
		yield put(mergesActions.loadData());
		yield put(buildsActions.loadData());
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchFetchBranchList() {
	yield takeLatest([branchActions.Types.LOAD_DATA], fetchBranchList);
}

export function* watchMergeBranch() {
	yield takeLatest([branchActions.Types.MERGE_BRANCH], mergeBranch);
}

export function* watchRebaseBranch() {
	yield takeLatest([branchActions.Types.REBASE_BRANCH], rebaseBranch);
}
