import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as buildsActions from '../../../actions/build/builds';
import {
	getProjectId,
} from '../../../selectors/router';
import {request} from "../../request";
import * as api from "../../../services/api/build/builds";
import * as notificationsActions from "../../../actions/notifications";
import { getEnvironmentsData } from '../deploy';

function* getBuildsData() {
	const projectId = yield select(getProjectId);

	const buildsData = yield call(request, {
		entity: buildsActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		},
	});
	const body = buildsData.payload.body;

	if (body.success) {
		yield put(buildsActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* getBuildById({
	payload: {
		buildId,
	},
}: any) {
	const projectId = yield select(getProjectId);

	const buildsData = yield call(request, {
		entity: buildsActions.Types.LOAD_DATA_BY_ID,
		callback: api.getById,
		params: {
			projectId,
			buildId,
		},
	});
	const body = buildsData.payload.body;

	if (body.success) {
		yield put(buildsActions.loadDataByIdReceived(Object.assign({}, body, {
			buildId,
		})));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* addBuild({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		resolve,
		reject,
	} = payload;

	const buildsData = yield call(request, {
		entity: buildsActions.Types.ADD_DATA,
		callback: api.addBuild,
		params: {
			projectId,
			body: values,
		}
	});

	const body = buildsData.payload.body;

	if (body.success) {
		yield call(getBuildsData);
		yield call(getEnvironmentsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchAddBuildsData() {
	yield takeLatest([buildsActions.Types.ADD_DATA], addBuild);
}

export function* watchGetBuildsData() {
	yield takeLatest([buildsActions.Types.LOAD_DATA], getBuildsData);
}

export function* watchGetBuildById() {
	yield takeLatest([buildsActions.Types.LOAD_DATA_BY_ID], getBuildById);
}
