import request, {bodyParser, getDefaultHeaders} from "../services/request";

export function getTokenExpDate(token: string) {
	if (token && token.split) {
		const encodedPayload = token.split('.')[1];
		const { exp } = JSON.parse(atob(encodedPayload));

		return exp * 1000;
	}

	return null;
}

export function getUser(token: string) {
	if (token && token.split) {
		const encodedPayload = token.split('.')[1];
		return JSON.parse(atob(encodedPayload));
	}

	return null;
}

export function getAccessToken() {
	const tokenData = localStorage.getItem('ZEMUZ:AUTH_TOKENS') as any;
	if (tokenData) {
		const tokens = JSON.parse(tokenData);
		return tokens.accessToken;
	}

	return null;
}

export async function renewToken(tokens: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/account/token/renew`,
		headers: await getDefaultHeaders(tokens),
		resolveWithFullResponse: true,
		body: JSON.stringify({
			refreshToken: tokens.refreshToken,
		}),
	})
		.then(bodyParser);
}

export const isLoggedIn = async () => {
	const tokenData = localStorage.getItem('ZEMUZ:AUTH_TOKENS') as any;
	if (tokenData) {
		const tokens = JSON.parse(tokenData);
		const accessToken = tokens.accessToken;

		const tokenExpiration = getTokenExpDate(accessToken);

		if (!tokenExpiration) {
			return false;
		}

		if (tokenExpiration <= +new Date()) {
			try {
				const newTokenResponse = await renewToken(tokens);

				if (newTokenResponse.success) {
					localStorage.setItem('ZEMUZ:AUTH_TOKENS', JSON.stringify(newTokenResponse.data));
					return true;
				}

				return false;
			} catch (e) {
				return false;
			}
		} else {
			return true;
		}
	}
};

export const getAuthTokens = async () => {
	if (await isLoggedIn()) {
		return JSON.parse(localStorage.getItem('ZEMUZ:AUTH_TOKENS') as any);
	} else {
		return null;
	}
};

export const logOut = () => {
	localStorage.removeItem('ZEMUZ:AUTH_TOKENS');
};
