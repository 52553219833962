import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Field} from 'redux-form';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import classNames from 'classnames';

const styles: any = {
	formControl: {
		width: '100%',
		marginTop: 4,
		height: 64,
	},
	multiline: {
		height: 'auto',
	},
	textField: {
		marginTop: 4,
		marginBottom: 0,
	},
	formControlOutlined: {
		height: 60,
		transition: 'margin 0.3s',
		marginTop: 0,
		'& input': {
			fontSize: '0.9rem',
			padding: 14,
		},
		'& label': {
			fontSize: '0.9rem',
			marginTop: -5,
		},
	},
	formControlOutlinedErrored: {
		marginBottom: 20,
	},
	errorMessage: {
		marginTop: 4,
	},
};

const renderTextField = ({
	label,
	input,
	classes = {},
	meta = {},
	variant,
	...rest
}: any) => {
	return (
		<FormControl className={classNames(classes.formControl, {
			[classes.formControlOutlined]: variant === 'outlined',
			[classes.formControlOutlinedErrored]: meta.touched && meta.invalid,
			[classes.multiline]: rest.multiline,
		})} error={meta.touched && meta.invalid}>
			<TextField
				className={classes.textField}
				label={label}
				variant={variant}
				error={meta.touched && meta.invalid}
				{...input}
				{...rest}
			/>
			{meta.touched && meta.invalid && meta.error && (
				<FormHelperText data-name={`${input.name}_error-message`} className={classes.errorMessage}>{meta.error}</FormHelperText>
			)}
		</FormControl>
	);
};

export default withStyles(styles)(({
	name,
	label,
	...rest
}: any) => (
	<Field
		name={name}
		label={label}
		component={renderTextField}
		{...rest}
	/>
));
