import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'PROJECT_LOAD_DATA',
	LOCAL_UPDATE_DATA = 'PROJECT_LOCAL_UPDATE_DATA',
	SOFT_UPDATE_DATA = 'PROJECT_SOFT_UPDATE_DATA',
	UPDATE_DATA = 'PROJECT_UPDATE_DATA',
	UPDATE_SETTINGS = 'PROJECT_UPDATE_SETTINGS',
	LOAD_DATA_RECEIVED = 'PROJECT_LOAD_DATA_RECEIVED',
	ADD_DATA = 'PROJECT_ADD_DATA',
	INVALIDATE_DATA = 'PROJECT_INVALIDATE_DATA',
	UPDATE_GIT_ORIGIN = 'PROJECT_UPDATE_GIT_ORIGIN',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const localUpdateData = createAction(Types.LOCAL_UPDATE_DATA);
export const softUpdateData = createAction(Types.SOFT_UPDATE_DATA);
export const updateData = createAction(Types.UPDATE_DATA);
export const updateSettings = createAction(Types.UPDATE_SETTINGS);
export const addData = createAction(Types.ADD_DATA);
export const invalidateData = createAction(Types.INVALIDATE_DATA);
export const updateGitOrigin = createAction(Types.UPDATE_GIT_ORIGIN);
