import {createAction} from 'redux-actions';

export enum Types {
	REMOVE = 'NOTIFICATION_REMOVE',
	ADD_ERROR = 'ADD_ERROR',
	ADD_SUCCESS = 'ADD_SUCCESS',
	ADD_WARNING = 'ADD_WARNING',
	ADD_INFO = 'ADD_INFO',
}

export const remove = createAction(Types.REMOVE);
export const addError = createAction(Types.ADD_ERROR);
export const addSuccess = createAction(Types.ADD_SUCCESS);
export const addWarning = createAction(Types.ADD_WARNING);
export const addInfo = createAction(Types.ADD_INFO);
