import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {EntitiesReduxStateInterface} from '../../interfaces/develop/Entity';
import * as EntitiesActions from '../../actions/develop/entities';
import uuid from 'uuid';

const initialData: EntitiesReduxStateInterface = {
	list: List(),
	focusEntityProperty: null,
};

const EntitiesData = Record(initialData);

export default handleActions<any, any>(
	{
		[EntitiesActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			return state
				.set('list', List(action.payload.data));
		},
		[EntitiesActions.Types.ADD_BLANK_PROPERTY]: (state, {
			payload: {
				id,
			}
		}: any) => {
			const newId = uuid();

			return state
				.set('list', state.list.update(
					state.list.findIndex((document: any) => document.id === id),
					(item: any) => {
						item.properties.push({
							editMode: true,
							id: newId,
							name: '',
							type: 'string',
							subtype: 'string',
							fakerType: 'lorem',
							fakerSubType: 'word',
						});

						return Object.assign({}, item, {
							properties: [...item.properties],
						});
					}
				))
				.set('focusEntityProperty', newId);
		},
		[EntitiesActions.Types.REMOVE_BLANK_PROPERTY]: (state, {
			payload: {
				id,
				entityId,
			}
		}: any) => {

			return state
				.set('list', state.list.update(
					state.list.findIndex((document: any) => document.id === entityId),
					(item: any) => {
						return Object.assign({}, item, {
							properties: [...item.properties.filter((property: any) => property.id !== id)],
						});
					}
				));
		},
		[EntitiesActions.Types.SET_BLANK_PROPERTY_VALUE]: (state, {
			payload: {
				id,
				values,
			}
		}: any) => {
			return state
				.set('list', state.list.update(
					state.list.findIndex((document: any) => document.id === id),
					(item: any) => {
						const i = item.properties.findIndex((property: any) => property.id === values.id);

						item.properties[i] = Object.assign({}, item.properties[i], values);

						return Object.assign({}, item, {
							properties: [...item.properties],
						});
					}
				));
		},
		[EntitiesActions.Types.SET_ENTITY_PROPERTY_FOCUS]: (state, {
			payload,
		}: any) => {
			return state
				.set('focusEntityProperty', payload);
		},
		[EntitiesActions.Types.SOFT_UPDATE_DATA]: (state: any, {
			payload: {
				values: {
					id,
					propertyId,
					...fields
				},
			},
		}: any) => {
			if (!propertyId) {
				return state
					.set('list', state.list.update(
						state.list.findIndex((document: any) => document.id === id),
						(item: any) => Object.assign({}, item, fields)
					));
			} else {
				return state
					.set('list', state.list.update(
						state.list.findIndex((document: any) => document.id === id),
						(item: any) => Object.assign({}, item, {
							properties: item.properties.map((property: any) => {
								if (property.id === propertyId) {
									return Object.assign({}, property, fields);
								}

								return property;
							}),
						}),
					));
			}
		},
		[EntitiesActions.Types.LOCAL_UPDATE_DATA]: (state: any, {
			payload: {
				values: {
					id,
					propertyId,
					...fields
				},
			},
		}: any) => {
			if (!propertyId) {
				return state
					.set('list', state.list.update(
						state.list.findIndex((document: any) => document.id === id),
						(item: any) => Object.assign({}, item, fields)
					));
			} else {
				return state
					.set('list', state.list.update(
						state.list.findIndex((document: any) => document.id === id),
						(item: any) => Object.assign({}, item, {
							properties: item.properties.map((property: any) => {
								if (property.id === propertyId) {
									return Object.assign({}, property, fields);
								}

								return property;
							}),
						}),
					));
			}
		},
	},
	new EntitiesData(),
);
