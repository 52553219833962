import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {ProjectsReduxStateInterface} from '../interfaces/Project';
import * as ProjectsActions from '../actions/projects';

const initialData: ProjectsReduxStateInterface = {
	list: List(),
};

const ProductsData = Record(initialData);

export default handleActions<any, any>(
	{
		[ProjectsActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			return state
				.set('list', List(action.payload.data));
		},
		[ProjectsActions.Types.INVALIDATE_DATA]: (state) => {
			return state
				.set('list', List());
		},
		[ProjectsActions.Types.LOCAL_UPDATE_DATA]: (state: any, {
			payload: {
				values: {
					id,
					...fields
				},
			}
		}: any) => {
			return state
				.set('list', state.list.update(
					state.list.findIndex((document: any) => document.id === id),
					(item: any) => Object.assign({}, item, fields)
				));
		},
	},
	new ProductsData(),
);
