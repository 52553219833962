import path from '../utils/pathSelector';
import {ReduxRootStateInterface} from '../interfaces/Redux';
import {getRequestsByName} from '../utils/requests';
import {Types as DatabasesActionTypes} from '../actions/databases';

export const getDatabasesData = path(['databases', 'list']);

export const isAddDatabasesLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, DatabasesActionTypes.ADD_DATA) || !!getRequestsByName(inProgressRequests, DatabasesActionTypes.LOAD_DATA);
};

export const isGetDatabasesLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, DatabasesActionTypes.LOAD_DATA);
};

export const isDeleteDatabasesLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, DatabasesActionTypes.LOAD_DATA) || !!getRequestsByName(inProgressRequests, DatabasesActionTypes.REMOVE_DATA);
};
