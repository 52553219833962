import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {BuildsReduxStateInterface} from '../../interfaces/build/Build';
import * as BuildsActions from '../../actions/build/builds';

const initialData: BuildsReduxStateInterface = {
	list: List(),
};

const BuildsData = Record(initialData);

export default handleActions<any, any>(
	{
		[BuildsActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			return state
				.set('list', List(action.payload.data));
		},
		[BuildsActions.Types.LOAD_DATA_BY_ID_RECEIVED]: (state, action) => {
			const buildIndex = state.list.findIndex(({ id }: any) => id === action.payload.buildId);
			const list = state.list.toJS();

			if (buildIndex === -1) {
				return state
					.set('list', List([
						...action.payload.data,
						...list,
					]));
			}

			return state
				.set('list', List([
					...list.slice(0, buildIndex),
					...action.payload.data,
					...list.slice(buildIndex + 1, list.length),
				]));
		},
	},
	new BuildsData(),
);
