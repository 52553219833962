import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { ReduxRootStateInterface } from '../interfaces/Redux';
import requestsReducer from './requests';
import notificationsReducer from './notifications';
import projectsReducer from './projects';
import authorizationsReducer from './authorizations';
import databasesReducer from './database';
import cmsReducer from './cms/cmsReducer';
import entitiesReducer from './develop/entities';
import routersReducer from './develop/routers';
import environmentsReducer from './deploy/environments';
import environmentVariablesReducer from './deploy/environmentVariables';
import buildsReducer from './build/builds';
import codeReviewReducer from './sourceCode/codeReview';
import appSettingsReducer from './appSettings';
import branchReducer from './merge/branch';
import mergeReducer from './merge/merge';
import developersReducer from './settings/developers';
import sshKeysReducer from './account/sshKeys';
import pluginsSettingsReducer from './plugins/settings';
import {createBrowserHistory} from 'history';
import {reducer as formReducer} from 'redux-form';

const history = createBrowserHistory();

export const rootReducer = combineReducers<ReduxRootStateInterface>({
	requests: requestsReducer,
	notifications: notificationsReducer,
	projects: projectsReducer,
	authorizations: authorizationsReducer,
	databases: databasesReducer,
	entities: entitiesReducer,
	routers: routersReducer,
	environments: environmentsReducer,
	environmentVariables: environmentVariablesReducer,
	builds: buildsReducer,
	appSettings: appSettingsReducer,
	codeReview: codeReviewReducer,
	cms: cmsReducer,
	router: connectRouter(history),
	form: formReducer,
	branch: branchReducer,
	merge: mergeReducer,
	developers: developersReducer,
	sshKeys: sshKeysReducer,
	pluginsSettings: pluginsSettingsReducer,
});
