import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {AuthorizationsReduxStateInterface} from '../interfaces/Authorization';
import * as AuthorizationsActions from '../actions/authorizations';

const initialData: AuthorizationsReduxStateInterface = {
	list: List(),
};

const ProductsData = Record(initialData);

export default handleActions<any, any>(
	{
		[AuthorizationsActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			if (action.payload.data.length) {
				return state
					.set('list', List(action.payload.data));
			} else {
				return state;
			}
		},
	},
	new ProductsData(),
);
