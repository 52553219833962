import {handleActions} from 'redux-actions';
import {Record, Map, List} from 'immutable';
import {CMSReduxStateInterface} from '../../interfaces/cms/CMS';
import * as CMSActions from "../../actions/cms/cms";

const initialData: CMSReduxStateInterface = {
	environmentBuildDefinition: Map(),
	activeEnvironment: null,
	activeEntity: Map(),
	activeEntityRoute: Map(),
	entitiesData: Map(),
	authorizers: Map(),
};

const defaultEntityData: any = {
	list: List(),
	size: 0,
	filters: {},
	sort: {},
	hiddenColumns: [],
	searchText: undefined,
	pagination: {
		page: 0,
		limit: 10,
	},
};

const CMSData = Record(initialData);

export default handleActions<any, any>(
	{
		[CMSActions.Types.SET_ACTIVE_ENVIRONMENT]: (state, action) => {
			const {
				payload: {
					data: {
						environment,
					},
				},
			} = action;

			return state
				.set('activeEnvironment', environment);
		},
		[CMSActions.Types.CHECK_AUTHORIZATION_CREDENTIALS_RECEIVED]: (state, action) => {
			const {
				payload: {
					authorizationName,
					value,
				},
			} = action;

			return state
				.set('authorizers', state.get('authorizers').set(authorizationName, value));
		},
		[CMSActions.Types.SET_ACTIVE_ENTITY]: (state, action) => {
			const {
				payload: {
					data: {
						entity,
					},
				},
			} = action;

			const { endpointName } = entity.filterEndpoints[0];

			const activeEnvironment = state.get('activeEnvironment');

			let environmentData = state
				.get('entitiesData')
				.get(activeEnvironment.id, Map());

			if (!environmentData.get(endpointName)) {
				environmentData = environmentData.set(endpointName, Map(defaultEntityData));
			}

			return state
				.set('activeEntity', state.get('activeEntity').merge({
					[activeEnvironment.id]: entity,
				}))
				.set('activeEntityRoute', state.get('activeEntityRoute').merge({
					[activeEnvironment.id]: entity.filterEndpoints && entity.filterEndpoints[0],
				}))
				.set('entitiesData', state.get('entitiesData').merge({
					[activeEnvironment.id]: environmentData,
				}));
		},
		[CMSActions.Types.SET_ACTIVE_ENTITY_ROUTE]: (state, action) => {
			const {
				payload: {
					data: {
						route,
					},
				},
			} = action;

			const endpointName = route.endpointName;
			const activeEnvironment = state.get('activeEnvironment');

			let environmentData = state
				.get('entitiesData')
				.get(activeEnvironment.id, Map());

			if (!environmentData.get(endpointName)) {
				environmentData = environmentData.set(endpointName, Map(defaultEntityData));
			}

			return state
				.set('activeEntityRoute', state.get('activeEntityRoute').merge({
					[activeEnvironment.id]: route,
				}))
				.set('entitiesData', state.get('entitiesData').merge({
					[activeEnvironment.id]: environmentData,
				}));
		},
		[CMSActions.Types.LOAD_ENVIRONMENT_BUILD_DEFINITION_RECEIVED]: (state, action) => {
			const {
				payload: {
					data: {
						environmentId,
						environmentData,
					},
				},
			} = action;

			return state
				.set('environmentBuildDefinition', state.get('environmentBuildDefinition').merge({
					[environmentId]: environmentData,
				}));
		},
		[CMSActions.Types.LOAD_ENTITY_DATA_RECEIVED]: (state, action) => {
			const {
				payload: {
					environmentId,
					endpointName,
					data,
					size,
				},
			} = action;

			let environment = state.get('entitiesData').get(environmentId);

			const entity = environment
				.get(endpointName)
				.set('list', List(data))
				.set('size', size)
				.set('filtersDisabled', false)
				.set('sortDisabled', false)
				.set('paginationDisabled', false);

			environment = environment.merge({
				[endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[environmentId]: environment,
			}));
		},
		[CMSActions.Types.SET_ENTITY_SORT]: (state, action) => {
			const {
				payload: {
					sort,
				},
			} = action;

			const activeEnvironment = state.get('activeEnvironment');
			const activeEntityRoute = state.get('activeEntityRoute').get(activeEnvironment.id);
			let environment = state.get('entitiesData').get(activeEnvironment.id);

			const entity = environment
				.get(activeEntityRoute.endpointName)
				.set('sort', sort);

			environment = environment.merge({
				[activeEntityRoute.endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[activeEnvironment.id]: environment,
			}));
		},
		[CMSActions.Types.SET_ENTITY_FILTERS]: (state, action) => {
			const {
				payload: {
					fieldName,
					fieldValue,
					fieldType,
				},
			} = action;

			const activeEnvironment = state.get('activeEnvironment');
			const activeEntityRoute = state.get('activeEntityRoute').get(activeEnvironment.id);
			let environment = state.get('entitiesData').get(activeEnvironment.id);

			const entity = environment
				.get(activeEntityRoute.endpointName)
				.set('filters', Object.assign({}, environment.get(activeEntityRoute.endpointName).get('filters'), {
					[fieldName]: (!Array.isArray(fieldValue) || fieldValue.length) ? {
						fieldValue,
						fieldType,
					} : undefined,
				}));

			environment = environment.merge({
				[activeEntityRoute.endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[activeEnvironment.id]: environment,
			}));
		},
		[CMSActions.Types.UPDATE_ENTITY_FILTERS]: (state, action) => {
			const {
				payload,
			} = action;

			const activeEnvironment = state.get('activeEnvironment');
			const activeEntityRoute = state.get('activeEntityRoute').get(activeEnvironment.id);
			let environment = state.get('entitiesData').get(activeEnvironment.id);

			const entity = environment
				.get(activeEntityRoute.endpointName)
				.set('filters', payload);

			environment = environment.merge({
				[activeEntityRoute.endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[activeEnvironment.id]: environment,
			}));
		},
		[CMSActions.Types.VIEW_REFERENCE_DETAILS]: (state, action) => {
			const {
				payload: {
					activeEntity: {
						data: {
							entity,
						},
					},
					filters,
				},
			} = action;

			const { endpointName } = entity.filterEndpoints[0];

			const activeEnvironment = state.get('activeEnvironment');

			let environmentData = state
				.get('entitiesData')
				.get(activeEnvironment.id, Map());

			if (!environmentData.get(endpointName)) {
				environmentData = environmentData.set(endpointName, Map(defaultEntityData));
			}

			const newEntity = environmentData
				.get(endpointName)
				.set('filters', filters);

			environmentData = environmentData.merge({
				[endpointName]: newEntity,
			});

			return state
				.set('activeEntity', state.get('activeEntity').merge({
					[activeEnvironment.id]: entity,
				}))
				.set('activeEntityRoute', state.get('activeEntityRoute').merge({
					[activeEnvironment.id]: entity.filterEndpoints && entity.filterEndpoints[0],
				}))
				.set('entitiesData', state.get('entitiesData').merge({
					[activeEnvironment.id]: environmentData,
				}));
		},
		[CMSActions.Types.VIEW_ARRAY_OF_DETAILS]: (state, action) => {
			const {
				payload: {
					activeEntity: {
						data: {
							entity,
						},
					},
					filters,
					data,
				},
			} = action;
			let endpointName;

			if (!entity) {
				return state;
			}

			if (entity.filterEndpoints && entity.filterEndpoints.length) {
				endpointName = entity.filterEndpoints[0].endpointName;
			} else {
				endpointName = 'NONE';

				entity.filterEndpoints = [{
					endpointName: 'NONE',
				}];
			}

			const activeEnvironment = state.get('activeEnvironment');

			let environmentData = state
				.get('entitiesData')
				.get(activeEnvironment.id, Map());

			if (!environmentData.get(endpointName)) {
				environmentData = environmentData.set(endpointName, Map(defaultEntityData));
			}

			const newEntity = environmentData
				.get(endpointName)
				.set('filters', filters)
				.set('list', List(data))
				.set('size', data.length)
				.set('filtersDisabled', true)
				.set('sortDisabled', true)
				.set('paginationDisabled', true);

			environmentData = environmentData.merge({
				[endpointName]: newEntity,
			});

			return state
				.set('activeEntity', state.get('activeEntity').merge({
					[activeEnvironment.id]: entity,
				}))
				.set('activeEntityRoute', state.get('activeEntityRoute').merge({
					[activeEnvironment.id]: entity.filterEndpoints && entity.filterEndpoints[0],
				}))
				.set('entitiesData', state.get('entitiesData').merge({
					[activeEnvironment.id]: environmentData,
				}));
		},
		[CMSActions.Types.SET_ENTITY_PAGINATION_ROWS_PER_PAGE]: (state, action) => {
			const {
				payload: {
					limit,
				},
			} = action;

			const activeEnvironment = state.get('activeEnvironment');
			const activeEntityRoute = state.get('activeEntityRoute').get(activeEnvironment.id);
			let environment = state.get('entitiesData').get(activeEnvironment.id);

			const entity = environment
				.get(activeEntityRoute.endpointName)
				.set('pagination', Object.assign({}, environment.get(activeEntityRoute.endpointName).get('pagination'), {
					limit,
				}));

			environment = environment.merge({
				[activeEntityRoute.endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[activeEnvironment.id]: environment,
			}));
		},
		[CMSActions.Types.SET_ENTITY_PAGINATION_CURRENT_PAGE]: (state, action) => {
			const {
				payload: {
					page,
				},
			} = action;

			const activeEnvironment = state.get('activeEnvironment');
			const activeEntityRoute = state.get('activeEntityRoute').get(activeEnvironment.id);
			let environment = state.get('entitiesData').get(activeEnvironment.id);

			const entity = environment
				.get(activeEntityRoute.endpointName)
				.set('pagination', Object.assign({}, environment.get(activeEntityRoute.endpointName).get('pagination'), {
					page,
				}));

			environment = environment.merge({
				[activeEntityRoute.endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[activeEnvironment.id]: environment,
			}));
		},
		[CMSActions.Types.SET_ENTITY_HIDDEN_COLUMNS]: (state, action) => {
			const {
				payload: {
					hiddenColumns,
				},
			} = action;

			const activeEnvironment = state.get('activeEnvironment');
			const activeEntityRoute = state.get('activeEntityRoute').get(activeEnvironment.id);
			let environment = state.get('entitiesData').get(activeEnvironment.id);

			const entity = environment
				.get(activeEntityRoute.endpointName)
				.set('hiddenColumns', hiddenColumns);

			environment = environment.merge({
				[activeEntityRoute.endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[activeEnvironment.id]: environment,
			}));
		},
		[CMSActions.Types.SET_ENTITY_SEARCH_TEXT]: (state, action) => {
			const {
				payload: {
					searchText,
				},
			} = action;

			const activeEnvironment = state.get('activeEnvironment');
			const activeEntityRoute = state.get('activeEntityRoute').get(activeEnvironment.id);
			let environment = state.get('entitiesData').get(activeEnvironment.id);

			const entity = environment
				.get(activeEntityRoute.endpointName)
				.set('searchText', searchText);

			environment = environment.merge({
				[activeEntityRoute.endpointName]: entity,
			});

			return state.set('entitiesData', state.get('entitiesData').merge({
				[activeEnvironment.id]: environment,
			}));
		},
	},
	new CMSData(),
);
