import {handleActions} from 'redux-actions';
import {Record} from 'immutable';
import {
	AppSettingsInterface,
} from '../interfaces/AppSettings';
import * as AppSettingsActions from '../actions/appSettings';

const navigatorWithDrawerOpened = localStorage.getItem('APP_SETTINGS:navigatorWithDrawerOpened') !== 'false';
const authTokens = localStorage.getItem('ZEMUZ:AUTH_TOKENS') || '{}';

const initialData: AppSettingsInterface = {
	navigatorWithDrawerOpened,
	authTokens: JSON.parse(authTokens),
};

const AppSettingsData = Record(initialData);

export default handleActions<any, any>(
	{
		[AppSettingsActions.Types.SET_NAVIGATOR_WITH_DRAWER_OPEN_STATE]: (state: any, action: any) => {
			localStorage.setItem('APP_SETTINGS:navigatorWithDrawerOpened', action.payload);

			return state.set('navigatorWithDrawerOpened', action.payload);
		},
	},
	new AppSettingsData(),
);
