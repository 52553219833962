import {
	call,
	takeLatest,
	put,
	select,
} from 'redux-saga/effects';
import * as projectsActions from '../../actions/projects';
import * as api from '../../services/api/projects';
import {request} from '../request';
import * as notificationsActions from "../../actions/notifications";
import {stopSubmit} from "redux-form";
import {apiValidationMapper} from "../../utils/reduxForm";
import * as changeCase from "change-case";
import {getProjectId} from "../../selectors/router";

const DATABASES_COLORS = ['default', 'blue', 'red', 'green', 'orange', 'purple', 'black', 'white'];

function getDatabaseColor(i:number) {
	return DATABASES_COLORS[i];
}

function* getProjectsData() {
	const projectsData = yield call(request, {
		entity: projectsActions.Types.LOAD_DATA,
		callback: api.getAll,
	});
	const body = projectsData.payload.body;

	if (body.success) {
		yield put(projectsActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* updateGitOrigin({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		gitUsername,
		gitToken,
		gitRepoName,
		gitBranch,
		resolve,
		reject,
	} = payload;

	const projectData = yield call(request, {
		entity: projectsActions.Types.UPDATE_GIT_ORIGIN,
		callback: api.updateGitOrigin,
		params: {
			projectId,
			gitUsername,
			gitToken,
			gitRepoName,
			gitBranch,
		},
	});
	const body = projectData.payload.body;

	if (body.success) {
		yield put(projectsActions.invalidateData());
		yield put(projectsActions.loadData());
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* updateProjectData({payload}: any) {
	const {
		values: {
			id,
			...fields
		},
		resolve,
		reject,
	} = payload;

	const projectsData = yield call(request, {
		entity: projectsActions.Types.UPDATE_DATA,
		callback: api.updateProject,
		params: {
			projectId: id,
			body: fields,
		}
	});
	const body = projectsData.payload.body;

	if (body.success) {
		resolve && resolve();
	} else {
		yield put(stopSubmit('ProjectForm', apiValidationMapper(body)));
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* updateProjectSettings({payload}: any) {
	const {
		values: {
			id,
			...fields
		},
		resolve,
		reject,
	} = payload;

	const projectsData = yield call(request, {
		entity: projectsActions.Types.UPDATE_SETTINGS,
		callback: api.updateProject,
		params: {
			projectId: id,
			body: fields,
		}
	});
	const body = projectsData.payload.body;

	if (body.success) {
		yield call(getProjectsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* addProject({payload}: any) {
	const {
		values,
	} = payload;

	const project: any = {
		name: changeCase.pascalCase(values.name),
		defaultLanguage: values.defaultLanguage,
		languages: values.languages,
		description: values.description,
		programmingLanguage: values.programmingLanguage,
		protocol: values.protocol,
		plugins: values.plugins,
		pluginSettings: values.pluginSettings,
		databases: [{
			type: values.database,
			identifierName: `${values.database}`,
			name: changeCase.snakeCase(values.name),
			color: getDatabaseColor(0),
		}],
		subscription: {
			details: {
				package: values.package,
				basis: values.basis,
			},
		},
		repository: values.repository,
		ciService: values.ciService,
		deployment: values.deployment,
		artifact: values.artifact,
	};

	const projectData = yield call(request, {
		entity: projectsActions.Types.ADD_DATA,
		callback: api.addProject,
		params: {
			body: project,
		}
	});
	const body = projectData.payload.body;

	if (body.success) {
		const projectId = body.data.id;

		yield put(projectsActions.invalidateData());
		window.location.href = `/projects/${projectId}/develop/entities`;
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

export function* watchGetProjectsData() {
	yield takeLatest([projectsActions.Types.LOAD_DATA], getProjectsData);
}

export function* watchUpdateProject() {
	yield takeLatest([projectsActions.Types.UPDATE_DATA], updateProjectData);
}

export function* watchUpdateProjectSettings() {
	yield takeLatest([projectsActions.Types.UPDATE_SETTINGS], updateProjectSettings);
}

export function* watchAddProject() {
	yield takeLatest([projectsActions.Types.ADD_DATA], addProject);
}

export function* watchProjectUpdateGitOrigin() {
	yield takeLatest([projectsActions.Types.UPDATE_GIT_ORIGIN], updateGitOrigin);
}
