import {
	call,
	takeLatest,
	put,
	select,
} from 'redux-saga/effects';
import * as api from '../../../services/api/plugins/settings';
import * as projectApi from '../../../services/api/projects';
import {request} from '../../request';
import * as notificationsActions from "../../../actions/notifications";
import * as pluginsSettingsActions from "../../../actions/plugins/settings";
import * as projectsActions from "../../../actions/projects";
import {getProjectId} from '../../../selectors/router';
import {getProjectById} from '../../../selectors/projects';
import {getPluginsSettingsData as getPluginsSettings} from '../../../selectors/plugins/settings';

function* getPluginsSettingsData() {
	const projectId = yield select(getProjectId);

	const pluginsSettingsData = yield call(request, {
		entity: pluginsSettingsActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		},
	});
	const body = pluginsSettingsData.payload.body;

	if (body.success) {
		yield put(pluginsSettingsActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* removePluginsSettingsData({ payload }: any) {
	const projectId = yield select(getProjectId);
	const project = yield select(getProjectById(projectId));
	const pluginsSettings = yield select(getPluginsSettings);
	const plugin = pluginsSettings.find((plugin: any) => plugin.pluginName === payload.id);
	const pluginIndex = project.plugins.findIndex((pluginName: string) => payload.id === pluginName);
	const fields: any = {};

	Object.keys(payload.defaultSettings || {}).forEach((key: string) => {
		fields[key] = null;
	});

	fields.plugins = [...project.plugins.slice(0, pluginIndex), ...project.plugins.slice(pluginIndex + 1, project.plugins.length)];

	if (plugin) {
		const pluginSettingsData = yield call(request, {
			entity: pluginsSettingsActions.Types.REMOVE_DATA,
			callback: api.deletePlugin,
			params: {
				projectId,
				pluginId: plugin.id,
			},
		});

		const pluginSettingsDataBody = pluginSettingsData.payload.body;

		if (pluginSettingsDataBody.success) {
			yield put(pluginsSettingsActions.loadData());
		} else {
			return yield put(notificationsActions.addError({
				message: pluginSettingsDataBody.error.message || pluginSettingsDataBody.error.name,
			}));
		}
	}

	const projectsData = yield call(request, {
		entity: projectsActions.Types.UPDATE_DATA,
		callback: projectApi.updateProject,
		params: {
			projectId,
			body: fields,
		}
	});
	const projectsDataBody = projectsData.payload.body;

	if (projectsDataBody.success) {
		yield put(projectsActions.loadData());
	} else {
		yield put(notificationsActions.addError({
			message: projectsDataBody.error.message || projectsDataBody.error.name,
		}));
	}
}

function* addPluginsSettingsData({ payload }: any) {
	const projectId = yield select(getProjectId);

	const pluginSettingsData = yield call(request, {
		entity: pluginsSettingsActions.Types.ADD_DATA,
		callback: api.addPlugin,
		params: {
			projectId,
			body: {
				pluginName: payload.id,
				settings: payload.defaultSettings,
			},
		},
	});

	const pluginSettingsDataBody = pluginSettingsData.payload.body;

	if (pluginSettingsDataBody.success) {
		yield put(pluginsSettingsActions.loadData());
		yield put(projectsActions.loadData());
	} else {
		return yield put(notificationsActions.addError({
			message: pluginSettingsDataBody.error.message || pluginSettingsDataBody.error.name,
		}));
	}
}

export function* watchGetPluginsSettingsData() {
	yield takeLatest([pluginsSettingsActions.Types.LOAD_DATA], getPluginsSettingsData);
}

export function* watchAddPluginsSettingsData() {
	yield takeLatest([pluginsSettingsActions.Types.ADD_DATA], addPluginsSettingsData);
}

export function* watchRemovePluginsSettingsData() {
	yield takeLatest([pluginsSettingsActions.Types.REMOVE_DATA], removePluginsSettingsData);
}
