import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'DATABASE_LOAD_DATA',
	LOCAL_UPDATE_DATA = 'DATABASE_LOCAL_UPDATE_DATA',
	SOFT_UPDATE_DATA = 'DATABASE_SOFT_UPDATE_DATA',
	UPDATE_DATA = 'DATABASE_UPDATE_DATA',
	LOAD_DATA_RECEIVED = 'DATABASE_LOAD_DATA_RECEIVED',
	ADD_DATA = 'DATABASE_ADD_DATA',
	REMOVE_DATA = 'DATABASE_REMOVE_DATA',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const localUpdateData = createAction(Types.LOCAL_UPDATE_DATA);
export const softUpdateData = createAction(Types.SOFT_UPDATE_DATA);
export const updateData = createAction(Types.UPDATE_DATA);
export const addData = createAction(Types.ADD_DATA);
export const remove = createAction(Types.REMOVE_DATA);
