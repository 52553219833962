import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getAll({projectId}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CODE_URL}/projects/${projectId}/builds/filter`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify({
			pagination: {
				limit: 100,
			},
			sorting: {
				number: 'desc',
			},
		}),
	})
		.then(bodyParser);
}

export async function getById({projectId, buildId}: any) {
	return request.get({
		uri: `${process.env.REACT_APP_API_CODE_URL}/projects/${projectId}/builds/${buildId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addBuild({projectId, body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CODE_URL}/projects/${projectId}/builds`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}
