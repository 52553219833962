import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getAll({projectId}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/plugins`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function deletePlugin({projectId, pluginId}: any) {
	return request.delete({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/plugins/${pluginId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addPlugin({projectId, body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/plugins`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}
