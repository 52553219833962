import {ReduxRootStateInterface} from '../../interfaces/Redux';
import path from '../../utils/pathSelector';
import {getRequestsByName} from '../../utils/requests';
import {Types as ProjectStructureActionTypes} from '../../actions/sourceCode/projectStructure';

export const getActiveBuild = (state: ReduxRootStateInterface) => {
	return path(['codeReview', 'activeBuild'])(state);
};

export const getCompareToBuild = (state: ReduxRootStateInterface) => {
	return path(['codeReview', 'compareToBuild'])(state);
};

export const getProjectStructure = (state: ReduxRootStateInterface) => {
	const activeBuild = getActiveBuild(state);

	if (!activeBuild) {
		return null;
	} else {
		return path(['codeReview', 'projectStructure'])(state).get(activeBuild.id);
	}
};

export const isGetProjectStructureLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, ProjectStructureActionTypes.LOAD_DATA);
};

export const getEditorSourceCode = (state: ReduxRootStateInterface) => {
	const activeBuild = getActiveBuild(state);

	if (!activeBuild) {
		return null;
	} else {
		const environmentDetails = path(['codeReview', 'sourceCode'])(state).get(activeBuild.id);

		if (!environmentDetails || !environmentDetails.get('pages')) {
			return null;
		} else {
			const page = environmentDetails.get('openedPages')[environmentDetails.get('openedPageIndex')];

			if (!page) {
				return null;
			} else {
				return environmentDetails.get('pages').get(page.fullPath);
			}
		}
	}
};

export const getEditorSourceCodeLanguage = (page: any) => (_state: ReduxRootStateInterface) => {
	if (page) {
		return page.fileType || 'text';
	}

	return 'text';
};

export const getProjectOpenedFileIndex = (state: ReduxRootStateInterface) => {
	const activeBuild = getActiveBuild(state);

	if (!activeBuild) {
		return -1;
	} else {
		const environmentDetails = path(['codeReview', 'sourceCode'])(state).get(activeBuild.id);

		if (!environmentDetails) {
			return -1;
		} else {
			return environmentDetails.get('openedPageIndex');
		}
	}
};

export const getProjectOpenedFiles = (state: ReduxRootStateInterface) => {
	const activeBuild = getActiveBuild(state);

	if (!activeBuild) {
		return [];
	} else {
		const environmentDetails = path(['codeReview', 'sourceCode'])(state).get(activeBuild.id);

		if (!environmentDetails) {
			return [];
		} else {
			return environmentDetails.get('openedPages') || [];
		}
	}
};
