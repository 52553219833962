import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'MERGES_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'MERGES_LOAD_DATA_RECEIVED',
	INVALIDATE_DATA = 'INVALIDATE_DATA',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const invalidateData = createAction(Types.INVALIDATE_DATA);
