import pathSelector from './pathSelector';

export const genericValidation = (ModelClass: any) => (values: any) => {
	const model = new ModelClass(values);

	return model.runValidations();
};

export const apiValidationMapper = (apiBody: any) => {
	const errors = pathSelector(['error', 'meta', 'errors'])(apiBody);

	if (Array.isArray(errors)) {
		return errors.reduce((reduced: any, {
			property,
			constraints,
		}: any) => {
			if (Array.isArray(constraints)) {
				reduced[property] = constraints[0].message || constraints[0].code;
			}

			return reduced;
		}, {});
	}

	return {};
};
