import {ReduxRootStateInterface} from "../../interfaces/Redux";
import path from "../../utils/pathSelector";
import {getRequestsByName} from '../../utils/requests';
import {Types as CmsActionTypes} from '../../actions/cms/cms';

export const getActiveEnvironment = (state: ReduxRootStateInterface) => {
	return path(['cms', 'activeEnvironment'])(state);
};

export const getAuthorizers = (state: ReduxRootStateInterface) => {
	return path(['cms', 'authorizers'])(state);
};

export const getEnvironmentBuildDefinition = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);

	if (activeEnvironment) {
		return path(['cms', 'environmentBuildDefinition'])(state).get(activeEnvironment.id);
	}
};

export const isGetEnvironmentBuildDefinitionLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, CmsActionTypes.LOAD_ENVIRONMENT_BUILD_DEFINITION);
};

export const isGetEntitiesLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, CmsActionTypes.LOAD_ENTITY_DATA);
};

export const isCmsLoginLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, CmsActionTypes.CHECK_AUTHORIZATION_CREDENTIALS_LOGIN);
};

export const getActiveEntity = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);

	if (activeEnvironment) {
		return path(['cms', 'activeEntity'])(state).get(activeEnvironment.id);
	}
};

export const getActiveEntityRoute = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);

	if (activeEnvironment) {
		return path(['cms', 'activeEntityRoute'])(state).get(activeEnvironment.id);
	}
};

export const getEntityDataList = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);
	const searchText = getEntitySearchText(state);

	if (activeEnvironment && activeEntityRoute) {
		const list = path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('list');

		if (searchText) {
			return list.filter((item: any) => {
				return !!Object.keys(item).find((itemName: string) => {
					let itemValue = item[itemName];

					if (itemValue && itemValue.toLowerCase) {
						itemValue = itemValue.toLowerCase();
					}

					if (itemValue && itemValue.search) {
						return itemValue.search(searchText) !== -1;
					}

					return itemValue === searchText;
				});
			});
		}

		return list;
	}
};

export const isFiltersDisabled = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);
	if (activeEnvironment && activeEntityRoute) {

		return !!path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('filtersDisabled');
	}
};

export const isSortDisabled = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);
	if (activeEnvironment && activeEntityRoute) {

		return !!path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('sortDisabled');
	}
};

export const isPaginationDisabled = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);
	if (activeEnvironment && activeEntityRoute) {

		return !!path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('paginationDisabled');
	}
};

export const getEntityDataSort = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);

	if (activeEnvironment && activeEntityRoute) {
		return path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('sort');
	}
};

export const getEntityDataFilters = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);

	if (activeEnvironment && activeEntityRoute) {
		return path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('filters');
	}
};

export const getEntityDataPagination = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);

	if (activeEnvironment && activeEntityRoute) {
		return path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('pagination');
	}
};

export const getEntityDataSize = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);

	if (activeEnvironment && activeEntityRoute) {
		return path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('size');
	}
};

export const getEntityHiddenColumns = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);

	if (activeEnvironment && activeEntityRoute) {
		return path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('hiddenColumns');
	}
};

export const getEntitySearchText = (state: ReduxRootStateInterface) => {
	const activeEnvironment = getActiveEnvironment(state);
	const activeEntityRoute = getActiveEntityRoute(state);

	if (activeEnvironment && activeEntityRoute) {
		return path(['cms', 'entitiesData'])(state)
			.get(activeEnvironment.id)
			.get(activeEntityRoute.endpointName)
			.get('searchText');
	}
};
