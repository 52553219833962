import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'DEVELOP_ENTITIES_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'DEVELOP_ENTITIES_LOAD_DATA_RECEIVED',
	UPDATE_DATA = 'DEVELOP_ENTITIES_UPDATE_DATA',
	SOFT_UPDATE_DATA = 'DEVELOP_ENTITIES_SOFT_UPDATE_DATA',
	LOCAL_UPDATE_DATA = 'DEVELOP_ENTITIES_LOCAL_UPDATE_DATA',
	ADD_DATA = 'DEVELOP_ENTITIES_ADD_DATA',
	SOFT_ADD_DATA = 'DEVELOP_ENTITIES_SOFT_ADD_DATA',
	DELETE = 'DEVELOP_ENTITIES_DELETE',
	ADD_BLANK_PROPERTY = 'DEVELOP_ENTITIES_ADD_BLANK_PROPERTY',
	SET_BLANK_PROPERTY_VALUE = 'DEVELOP_ENTITIES_SET_BLANK_PROPERTY_VALUE',
	REMOVE_BLANK_PROPERTY = 'REMOVE_BLANK_PROPERTY',
	SET_ENTITY_PROPERTY_FOCUS = 'DEVELOP_ENTITIES_SET_ENTITY_PROPERTY_FOCUS',
	SAVE_ENTITY_PROPERTIES = 'DEVELOP_ENTITIES_SAVE_ENTITY_PROPERTIES',
	UPDATE_ENTITY_PROPERTY = 'DEVELOP_ENTITIES_UPDATE_ENTITY_PROPERTY',
	LINK_ENTITY_PROPERTY = 'DEVELOP_ENTITIES_LINK_ENTITY_PROPERTY',
	REMOVE_ENTITY_PROPERTY = 'DEVELOP_ENTITIES_REMOVE_ENTITY_PROPERTY',
}

export const loadData = createAction(Types.LOAD_DATA);
export const updateData = createAction(Types.UPDATE_DATA);
export const softUpdateData = createAction(Types.SOFT_UPDATE_DATA);
export const localUpdateData = createAction(Types.LOCAL_UPDATE_DATA);
export const addData = createAction(Types.ADD_DATA);
export const softAddData = createAction(Types.SOFT_ADD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const deleteEntity = createAction(Types.DELETE);
export const addBlankProperty = createAction(Types.ADD_BLANK_PROPERTY);
export const removeBlankProperty = createAction(Types.REMOVE_BLANK_PROPERTY);
export const setBlankPropertyValues = createAction(Types.SET_BLANK_PROPERTY_VALUE);
export const setEntityPropertyFocus = createAction(Types.SET_ENTITY_PROPERTY_FOCUS);
export const saveEntityProperties = createAction(Types.SAVE_ENTITY_PROPERTIES);
export const updateEntityProperty = createAction(Types.UPDATE_ENTITY_PROPERTY);
export const linkEntityProperty = createAction(Types.LINK_ENTITY_PROPERTY);
export const removeEntityProperty = createAction(Types.REMOVE_ENTITY_PROPERTY);
