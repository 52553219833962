import path from '../../utils/pathSelector';
import {ReduxRootStateInterface} from '../../interfaces/Redux';
import {getRequestsByName} from '../../utils/requests';
import {Types as RoutersActionTypes} from '../../actions/develop/routers';

export const getRoutersData = path(['routers', 'list']);

export const getRouterById = (routerId: string) => (state: ReduxRootStateInterface) => {
	const routers = getRoutersData(state);

	return routers.find(({id}: any) => id === routerId);
};

export const isGetRoutersLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, RoutersActionTypes.LOAD_DATA);
};

export const isSaveRoutersLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, RoutersActionTypes.ADD_DATA) || !!getRequestsByName(inProgressRequests, RoutersActionTypes.LOAD_DATA);
};

export const isDeleteRouterLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, RoutersActionTypes.DELETE) || !!getRequestsByName(inProgressRequests, RoutersActionTypes.LOAD_DATA);
};

export const isUpdateRouterMethodLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, RoutersActionTypes.UPDATE_METHOD) || !!getRequestsByName(inProgressRequests, RoutersActionTypes.LOAD_DATA);
};

export const getRoutersWithEntityData = (state: ReduxRootStateInterface) => {
	const routers = path(['routers', 'list'])(state);
	const entities = path(['entities', 'list'])(state);

	if (!routers.size || !entities.size) {
		return null;
	}

	const normalizedData: any = {};

	routers.forEach((router: any) => {
		const { entityId } = router;

		if (!normalizedData[entityId]) {
			normalizedData[entityId] = {
				entity: entities.find(({id}: any) => id === entityId),
				routers: [],
			};
		}

		normalizedData[entityId].routers.push(router);
	});

	return normalizedData;
};
