import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {EnvironmentsReduxStateInterface} from '../../interfaces/deploy/Environment';
import * as EnvironmentsActions from '../../actions/deploy/environments';

const initialData: EnvironmentsReduxStateInterface = {
	list: List(),
};

const EnvironmentsData = Record(initialData);

export default handleActions<any, any>(
	{
		[EnvironmentsActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			return state
				.set('list', List(action.payload.data));
		},
		[EnvironmentsActions.Types.SOFT_UPDATE_DATA]: (state: any, {
			payload: {
				values: {
					environmentId,
					...fields
				},
			}
		}: any) => {
			return state
				.set('list', state.list.update(
					state.list.findIndex((document: any) => document.id === environmentId),
					(item: any) => Object.assign({}, item, fields)
				));
		},
	},
	new EnvironmentsData(),
);
