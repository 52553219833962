import {handleActions} from 'redux-actions';
import {Record} from 'immutable';
import {EnvironmentVariablesReduxStateInterface} from '../../interfaces/deploy/EnvironmentVariable';
import * as EnvironmentVariablesActions from '../../actions/deploy/environmentVariables';

const initialData: EnvironmentVariablesReduxStateInterface = {
	boilerplate: null,
	zemuzCloud: null,
	docker: null,
};

const EnvironmentVariablesData = Record(initialData);

export default handleActions<any, any>(
	{
		[EnvironmentVariablesActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			return state
				.set('boilerplate', action.payload.data.boilerplate)
				.set('zemuzCloud', action.payload.data.zemuzCloud)
				.set('docker', action.payload.data.docker);
		},
	},
	new EnvironmentVariablesData(),
);
