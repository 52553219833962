import path from '../../utils/pathSelector';
import {ReduxRootStateInterface} from '../../interfaces/Redux';
import {getRequestsByName} from '../../utils/requests';
import {Types as PluginsSettingsActionTypes} from '../../actions/plugins/settings';

export const getPluginsSettingsData = path(['pluginsSettings', 'list']);

export const isGetPluginsSettingsDataLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, PluginsSettingsActionTypes.LOAD_DATA);
};
