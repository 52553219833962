import { handleActions } from 'redux-actions';
import { Record, List } from 'immutable';
import * as RequestsActions from '../actions/requests';
import {RequestsReduxStateInterface} from '../interfaces/Request';

const initialData: RequestsReduxStateInterface = {
	onGoing: List(),
	errored: List(),
};

const Request = Record(initialData);

const deleteRequest = (requests: List<any>, request: string) => {
	return requests.filter((item: string) => ( item !== request ));
};

export default handleActions<any, any>(
	{
		[RequestsActions.Types.STARTED]: (state, action) => {
			return state.set('onGoing', state.get('onGoing').push(action.payload.entity));
		},
		[RequestsActions.Types.CANCELLED]: (state, action) => {
			return state.set('onGoing', deleteRequest(state.get('onGoing'), action.payload.entity));
		},
		[RequestsActions.Types.SUCCEEDED]: (state, action) => {
			return state.set('onGoing', deleteRequest(state.get('onGoing'), action.payload.entity));
		},
		[RequestsActions.Types.ERRORED]: (state, action) => {
			return state
				.set('onGoing', deleteRequest(state.get('onGoing'), action.payload.entity))
				.set('errored', state.get('errored').push(action.payload.entity));
		},
	},
	new Request(),
);
