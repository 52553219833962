import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as environmentsActions from '../../../actions/deploy/environments';
import {
	getProjectId,
} from '../../../selectors/router';
import {request} from "../../request";
import * as api from "../../../services/api/deploy/environments";
import * as notificationsActions from "../../../actions/notifications";
import {getEnvironmentById} from "../../../selectors/deploy/environments";
import {
	getBoilerplateEnvironmentVariablesData,
	getDockerEnvironmentVariablesData,
} from "../../../selectors/deploy/environmentVariables";
import * as changeCase from 'change-case';

function *getEnvironmentVariables(orchestrationMethod: string, packagingMethod: string) {
	let environmentVariables = {};

	if (packagingMethod === 'docker' || orchestrationMethod === 'docker-compose') {
		environmentVariables = yield select(getDockerEnvironmentVariablesData);
	} else {
		environmentVariables = yield select(getBoilerplateEnvironmentVariablesData);
	}

	return environmentVariables;
}

function normalizeForApi(values: any, environmentVariables: any) {
	const data: any = {
		name: values.name,
		packagingMethod: values.packagingMethod,
		ciService: values.ciService,
		artifactLocation: values.artifactLocation,
		orchestrationMethod: values.orchestrationMethod,
	};

	data.mockConfig = Object.keys(values.mockData.mockConfig || {})
		.map((entityId: string) => ({
			entityId: entityId,
			size: values.mockData.mockConfig[entityId],
		}));

	if (environmentVariables && values.secrets) {
		data.deployConfig = Object.keys(environmentVariables || {}).map((serviceName: string) => {
			const definition: any = {
				serviceName,
				variables: [],
				serviceType: environmentVariables[serviceName].service.type,
				dependsOn: environmentVariables[serviceName].service.dependsOn,
			};

			Object.keys(environmentVariables[serviceName].variables).forEach((envName: string) => {
				definition.variables.push({
					name: envName,
					value: values.secrets.variables[`${serviceName}_${changeCase.constantCase(envName)}`],
				});
			});

			return definition;
		});

		if (environmentVariables.API) {
			const { variables } = values;

			if (variables) {
				const {
					API_BASE_URL,
					API_PORT,
				} = variables;

				if (API_BASE_URL) {
					data.publicUrl = `${API_BASE_URL}${API_PORT ? `:${API_PORT}` : ''}`;
				}
			}
		}
	}

	return data;
}

export function* getEnvironmentsData() {
	const projectId = yield select(getProjectId);

	const environmentsData = yield call(request, {
		entity: environmentsActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		}
	});
	const body = environmentsData.payload.body;

	if (body.success) {
		body.data = body.data.map((environment: any) => {
			environment.mockConfig = (environment.mockConfig || []).reduce((reduced: any, {
				entityId,
				size,
			}: any) => {
				reduced[entityId] = size;

				return reduced;
			}, {});
			environment.variables = (environment.variables || []).reduce((reduced: any, {
				name,
				value,
			}: any) => {
				reduced[name] = value;

				return reduced;
			}, {});

			return environment;
		});

		yield put(environmentsActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* addEnvironment({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		values: {
			packagingMethod,
			orchestrationMethod,
		},
		resolve,
		reject,
	} = payload;
	const environmentVariables = yield call(getEnvironmentVariables, orchestrationMethod, packagingMethod);

	const environmentsData = yield call(request, {
		entity: environmentsActions.Types.ADD_DATA,
		callback: api.addEnvironment,
		params: {
			projectId,
			body: normalizeForApi(values, environmentVariables),
		}
	});

	const body = environmentsData.payload.body;

	if (body.success) {
		yield call(getEnvironmentsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* updateData({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		values: {
			packagingMethod,
			orchestrationMethod,
		},
		resolve,
		reject,
	} = payload;

	const environmentVariables = yield call(getEnvironmentVariables, orchestrationMethod, packagingMethod);

	const environmentsData = yield call(request, {
		entity: environmentsActions.Types.UPDATE_DATA,
		callback: api.updateEnvironment,
		params: {
			projectId,
			environmentId: values.id,
			body: normalizeForApi(values, environmentVariables),
		}
	});

	const body = environmentsData.payload.body;

	if (body.success) {
		yield call(getEnvironmentsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* deploy({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values: {
			environmentId,
			mockConfig,
			regenerateMock,
			build,
		},
		resolve,
		reject,
	} = payload;

	const environmentsData = yield call(request, {
		entity: environmentsActions.Types.DEPLOY,
		callback: api.deploy,
		params: {
			projectId,
			environmentId,
			body: {
				build,
				mockConfig,
				regenerateMock,
			},
		}
	});

	const body = environmentsData.payload.body;

	if (body.success) {
		yield call(getEnvironmentsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* deleteEnvironment({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		resolve,
		reject,
	} = payload;

	const environmentsData = yield call(request, {
		entity: environmentsActions.Types.DELETE,
		callback: api.deleteEnvironment,
		params: {
			projectId,
			environmentId: values,
		}
	});
	const body = environmentsData.payload.body;

	if (body.success) {
		yield call(getEnvironmentsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* softUpdateData({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		values: {
			environmentId,
			...fields
		},
		resolve,
		reject,
	} = payload;

	const {
		orchestrationMethod,
		packagingMethod,
	} = values;
	const environment = yield select(getEnvironmentById(environmentId));
	const environmentVariables = yield call(getEnvironmentVariables, orchestrationMethod, packagingMethod);
	const environmentsData = yield call(request, {
		entity: environmentsActions.Types.SOFT_UPDATE_DATA,
		callback: api.updateEnvironment,
		params: {
			projectId,
			environmentId,
			body: normalizeForApi(Object.assign({}, environment, fields), environmentVariables),
		}
	});
	const body = environmentsData.payload.body;

	if (body.success) {
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchAddEnvironmentData() {
	yield takeLatest([environmentsActions.Types.ADD_DATA], addEnvironment);
}

export function* watchGetEnvironmentsData() {
	yield takeLatest([environmentsActions.Types.LOAD_DATA], getEnvironmentsData);
}

export function* watchDeleteEnvironment() {
	yield takeLatest([environmentsActions.Types.DELETE], deleteEnvironment);
}

export function* watchSoftUpdateEnvironment() {
	yield takeLatest([environmentsActions.Types.SOFT_UPDATE_DATA], softUpdateData);
}

export function* watchUpdateEnvironment() {
	yield takeLatest([environmentsActions.Types.UPDATE_DATA], updateData);
}

export function* watchDeployEnvironment() {
	yield takeLatest([environmentsActions.Types.DEPLOY], deploy);
}
