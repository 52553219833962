import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'DEPLOY_BUILDS_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'DEPLOY_BUILDS_LOAD_DATA_RECEIVED',
	LOAD_DATA_BY_ID = 'DEPLOY_BUILDS_LOAD_DATA_BY_ID',
	LOAD_DATA_BY_ID_RECEIVED = 'DEPLOY_BUILDS_LOAD_DATA_BY_ID_RECEIVED',
	UPDATE_DATA = 'DEPLOY_BUILDS_UPDATE_DATA',
	ADD_DATA = 'DEPLOY_BUILDS_ADD_DATA',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const loadDataById = createAction(Types.LOAD_DATA_BY_ID);
export const loadDataByIdReceived = createAction(Types.LOAD_DATA_BY_ID_RECEIVED);
export const updateData = createAction(Types.UPDATE_DATA);
export const addData = createAction(Types.ADD_DATA);
