import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'ACCOUNT_SSH_KEYS_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'ACCOUNT_SSH_KEYS_LOAD_DATA_RECEIVED',
	UPDATE_DATA = 'ACCOUNT_SSH_KEYS_UPDATE_DATA',
	ADD_DATA = 'ACCOUNT_SSH_KEYS_ADD_DATA',
	REMOVE_DATA = 'ACCOUNT_SSH_KEYS_REMOVE_DATA',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const updateData = createAction(Types.UPDATE_DATA);
export const addData = createAction(Types.ADD_DATA);
export const remove = createAction(Types.REMOVE_DATA);
