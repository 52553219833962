import path from '../../utils/pathSelector';
import {ReduxRootStateInterface} from '../../interfaces/Redux';
import {getRequestsByName} from '../../utils/requests';
import {Types as EnvironmentsActionTypes} from '../../actions/deploy/environments';

export const getEnvironmentsData = path(['environments', 'list']);

export const isGetEnvironmentsLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EnvironmentsActionTypes.LOAD_DATA);
};

export const isSaveEnvironmentLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EnvironmentsActionTypes.ADD_DATA) || !!getRequestsByName(inProgressRequests, EnvironmentsActionTypes.LOAD_DATA);
};

export const isDeployEnvironmentLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EnvironmentsActionTypes.DEPLOY) || !!getRequestsByName(inProgressRequests, EnvironmentsActionTypes.LOAD_DATA);
};

export const isDeleteEnvironmentLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EnvironmentsActionTypes.DELETE) || !!getRequestsByName(inProgressRequests, EnvironmentsActionTypes.LOAD_DATA);
};

export const getEnvironmentById = (environmentId: string) => (state: ReduxRootStateInterface) => {
	const environments = getEnvironmentsData(state);

	return environments.find(({id}: any) => id === environmentId);
};
