export default function(path: any) {
	return (obj: any) => {
		const pathCopy = [...path];

		let value = obj[pathCopy[0]];

		while (value) {
			if (pathCopy.length === 1) {
				return value;
			}

			obj = value;
			pathCopy.shift();
			value = obj[pathCopy[0]];
		}

		return;
	};
}
