import * as changeCase from 'change-case';

export const generateAuthModels = (authentication: any, databaseId: string) => {
	return authentication.filter(({type}: any) => type === 'JWT').map(({
		identifierName,
		groups,
		projectId,
	}: any) => {
		return {
			"projectId": projectId,
			"name": changeCase.pascalCase(`${identifierName} User`),
			"dbTableName": changeCase.snakeCase(`${identifierName}_users`),
			"databaseId": databaseId,
			"primaryKey": true,
			"timestamps": true,
			"disabled": true,
			"properties": [
				{
					"name": "Id",
					"type": "string",
					"subtype": "varchar",
					"autogenerated": true,
					"disabled": true,
				},
				{
					"name": "Created At",
					"type": "Date",
					"subtype": "datetime",
					"autogenerated": true,
					"fakerType": "date",
					"fakerSubType": "recent",
					"disabled": true,
				},
				{
					"name": "Updated At",
					"type": "Date",
					"subtype": "datetime",
					"autogenerated": true,
					"fakerType": "date",
					"fakerSubType": "recent",
					"disabled": true,
				},
				{
					"name": "Cognito Id",
					"type": "string",
					"subtype": "varchar",
					"disabled": true,
				},
				{
					"name": "Email",
					"type": "string",
					"subtype": "string",
					"fakerType": "internet",
					"fakerSubType": "email",
					"unique": true,
					"validators": {
						"required": true,
						"matches": true,
						"matchesRegexp": "/^\\w[\\w.-]*@([\\w-]+\\.)+[\\w-]+$/gi",
						"matchesRegexpErrorMessage": "Should be a valid email address"
					},
				},
				{
					"name": "Password",
					"type": "string",
					"subtype": "string",
					"fakerType": "internet",
					"fakerSubType": "password",
					"validators": {
						"minMaxLength": true,
						"minMaxLengthMinOption": 6,
						"matches": true,
						"matchesRegexp": "/^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[#$^+=!*()@%&])./g",
						"matchesRegexpErrorMessage": "Should contain at least one uppercase letter, one lowercase letter and one number"
					}
				},
				{
					"name": "Groups",
					"type": "arrayOf",
					"subtype": "primitiveValues",
					"fakerType": "oneOf",
					"fakerSubType": null,
					"fakerOptions": groups,
					"validators": {
						"required": true
					},
					"appearance": "tags",
					"modelSubType": "string",
					"modelType": "string"
				},
			],
		}
	});
};

export const generateAuthRouters = (authentication: any, ids: any) => {
	return authentication.filter(({type}: any) => type === 'JWT').reduce((reduced: any, {
		id,
		groups,
		identifierName,
	}: any, i: any) => {
		const [entityId, idPropertyId] = ids[i];

		reduced = [...reduced, ...[
			{
				"entityId": entityId,
				"ignoreOnBuild": true,
				"disabled": true,
				"endpointName": `/${changeCase.camelCase(identifierName)}`,
				"methods": [
					{
						"endpointName": `/${changeCase.camelCase(identifierName)}/register`,
						"name": "add",
						"authorization": {
							"authorizer": id,
							"isOptional": true,
						},
						"disabled": true,
					},
					{
						"endpointName": `/${changeCase.camelCase(identifierName)}/register/confirm`,
						"name": "add",
						"mockEntity": JSON.stringify({
							"name": "Register Confirm",
							"properties": [
								{
									"name": "Email",
								},
								{
									"name": "Verification code",
								}
							],
						}),
						"disabled": true,
					},
					{
						"endpointName": `/${changeCase.camelCase(identifierName)}/register/resend-code`,
						"name": "add",
						"mockEntity": JSON.stringify({
							"name": "Resend Confirmation Code",
							"properties": [
								{
									"name": "Email",
								},
							],
						}),
						"disabled": true,
					},
					{
						"name": "add",
						"endpointName": `/${changeCase.camelCase(identifierName)}/login`,
						"mockEntity": JSON.stringify({
							"name": "Login",
							"properties": [
								{
									"name": "Email",
								},
								{
									"name": "Password",
								},
							],
						}),
						"disabled": true,
					},
					{
						"name": "add",
						"endpointName": `/${changeCase.camelCase(identifierName)}/logout`,
						"authorization": {
							"authorizer": id,
							"isOptional": false,
						},
						"disabled": true,
					},
					{
						"name": "add",
						"endpointName": `/${changeCase.camelCase(identifierName)}/password/recover`,
						"mockEntity": JSON.stringify({
							"name": "Password Recover",
							"properties": [
								{
									"name": "Email",
								},
							],
						}),
						"disabled": true,
					},
					{
						"name": "add",
						"endpointName": `/${changeCase.camelCase(identifierName)}/password/recover/confirm`,
						"mockEntity": JSON.stringify({
							"name": "Password Recover",
							"properties": [
								{
									"name": "Email",
								},
								{
									"name": "New password",
								},
								{
									"name": "Verification code",
								},
							],
						}),
						"disabled": true,
					},
					{
						"name": "add",
						"endpointName": `/${changeCase.camelCase(identifierName)}/password/change`,
						"authorization": {
							"authorizer": id,
							"isOptional": false,
						},
						"mockEntity": JSON.stringify({
							"name": "Password Change",
							"properties": [
								{
									"name": "Email",
								},
								{
									"name": "Password",
								},
								{
									"name": "New password",
								},
							],
						}),
						"disabled": true,
					},
					{
						"name": "add",
						"endpointName": `/${changeCase.camelCase(identifierName)}/token/renew`,
						"mockEntity": JSON.stringify({
							"name": "PasswordChange",
							"properties": [
								{
									"name": "Email",
								},
								{
									"name": "Password",
								},
								{
									"name": "New password",
								},
							],
						}),
						"authorization": {
							"authorizer": id,
							"isOptional": false,
						},
						"disabled": true,
					},
				],
			},
			{
			"entityId": entityId,
			"endpointName": `/${changeCase.camelCase(identifierName)}/user`,
			"methods": [
				{
					"name": "getAll",
					"authorization": {
						"authorizer": id,
						"isOptional": false,
						"groups": [groups[0].value],
					},
				},
				{
					"name": "getById",
					"authorization": {
						"authorizer": id,
						"isOptional": false,
						"flow": {
							"identityValidations": [
								{
									"action": "checkEquality",
									"entityProperty": `${entityId}.${idPropertyId}`,
									"equalsToProperty": "{currentUserId}",
								}
							],
						},
					},
				},
				{
					"name": "add",
					"authorization": {
						"authorizer": id,
						"isOptional": true,
						"groups": [groups[0].value],
					},
				},
				{
					"name": "remove",
					"authorization": {
						"authorizer": id,
						"isOptional": false,
						"groups": [groups[0].value],
					},
				},
				{
					"name": "update",
					"authorization": {
						"authorizer": id,
						"isOptional": false,
						"flow": {
							"identityValidations": [
								{
									"action": "checkEquality",
									"entityProperty": `${entityId}.${idPropertyId}`,
									"equalsToProperty": "{currentUserId}",
								}
							],
						},
					},
				},
				{
					"name": "patch",
					"authorization": {
						"authorizer": id,
						"isOptional": false,
						"flow": {
							"identityValidations": [
								{
									"action": "checkEquality",
									"entityProperty": `${entityId}.${idPropertyId}`,
									"equalsToProperty": "{currentUserId}",
								}
							],
						},
					},
				},
				{
					"name": "getFilteredData",
					"authorization": {
						"authorizer": id,
						"isOptional": false,
						"groups": [groups[0].value],
					},
				},
			],
		}]];

		return reduced;
	}, []);
};
