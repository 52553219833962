import path from '../utils/pathSelector';
import {ReduxRootStateInterface} from '../interfaces/Redux';
import {getRequestsByName} from '../utils/requests';
import {getProjectId} from './router';
import {Types as ProjectsActionTypes} from '../actions/projects';

export const getProjectsData = path(['projects', 'list']);

export const isGetProjectsLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, ProjectsActionTypes.LOAD_DATA);
};

export const isUpdateProjectSettingsLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, ProjectsActionTypes.UPDATE_SETTINGS) || !!getRequestsByName(inProgressRequests, ProjectsActionTypes.LOAD_DATA);
};

export const isUpdateProjectLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, ProjectsActionTypes.UPDATE_DATA) || !!getRequestsByName(inProgressRequests, ProjectsActionTypes.LOAD_DATA);
};

export const isAddProjectsLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, ProjectsActionTypes.ADD_DATA);
};

export const getProjectById = (projectId: any) => (state: ReduxRootStateInterface) => {
	const projects = getProjectsData(state);

	return projects.find((project: any) => project.id === projectId) || {};
};

export const getCurrentProjectData = (state: ReduxRootStateInterface) => {
	const projectId = getProjectId(state);
	if (!projectId) {
		return null;
	}

	return getProjectById(projectId)(state);
};
