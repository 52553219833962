import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'MERGE_BRANCH_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'MERGE_BRANCH_LOAD_DATA_RECEIVED',
	MERGE_BRANCH = 'MERGE_BRANCH',
	MERGE_BRANCH_RECEIVED = 'MERGE_BRANCH_RECEIVED',
	MERGE_BRANCH_INVALIDATE_DATA = 'MERGE_BRANCH_INVALIDATE_DATA',
	REBASE_BRANCH = 'REBASE_BRANCH',
	REBASE_BRANCH_RECEIVED = 'REBASE_BRANCH_RECEIVED',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const mergeBranch = createAction(Types.MERGE_BRANCH);
export const mergeBranchReceived = createAction(Types.MERGE_BRANCH_RECEIVED);
export const rebaseBranch = createAction(Types.REBASE_BRANCH);
export const rebaseBranchReceived = createAction(Types.REBASE_BRANCH_RECEIVED);
export const invalidateData = createAction(Types.MERGE_BRANCH_INVALIDATE_DATA);
