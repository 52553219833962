import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as developersActions from '../../../actions/settings/developers';
import {
	getProjectId,
} from '../../../selectors/router';
import {request} from '../../request';
import * as api from '../../../services/api/settings/developer';
import * as notificationsActions from '../../../actions/notifications';

function* getDevelopersList() {
	const projectId = yield select(getProjectId);

	const developersData = yield call(request, {
		entity: developersActions.Types.LOAD_DATA,
		callback: api.getDevelopersList,
		params: {
			projectId,
		},
	});

	const body = developersData.payload.body;

	if (body.success) {
		yield put(developersActions.loadDataReceived({
			data: body.data,
		}));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* addDeveloper({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		email,
		gitUser,
		role,
		resolve,
		reject,
	} = payload;

	const addBranchData = yield call(request, {
		entity: developersActions.Types.ADD_DEVELOPER,
		callback: api.addDeveloper,
		params: {
			projectId,
			email,
			gitUser,
			role,
		},
	});

	const body = addBranchData.payload.body;

	if (body.success) {
		yield call(getDevelopersList);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* removeDeveloper({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		id,
		resolve,
		reject,
	} = payload;

	const removeDeveloperData = yield call(request, {
		entity: developersActions.Types.REMOVE_DEVELOPER,
		callback: api.removeDeveloper,
		params: {
			projectId,
			developerId: id,
		},
	});

	const body = removeDeveloperData.payload.body;

	if (body.success) {
		yield call(getDevelopersList);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchGetDevelopersList() {
	yield takeLatest([developersActions.Types.LOAD_DATA], getDevelopersList);
}

export function* watchAddDeveloper() {
	yield takeLatest([developersActions.Types.ADD_DEVELOPER], addDeveloper);
}

export function* watchRemoveDeveloper() {
	yield takeLatest([developersActions.Types.REMOVE_DEVELOPER], removeDeveloper);
}
