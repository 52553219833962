import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {SshKeyReduxStateInterface} from '../../interfaces/account/SshKey';
import * as SshKeysActions from '../../actions/account/sshKeys';

const initialData: SshKeyReduxStateInterface = {
	list: List(),
};

const SshKeysData = Record(initialData);

export default handleActions<any, any>(
	{
		[SshKeysActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			return state
				.set('list', List(action.payload.data));
		},
	},
	new SshKeysData(),
);
