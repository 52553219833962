import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as routersActions from '../../../actions/develop/routers';
import {
	getProjectId,
} from '../../../selectors/router';
import {
	getRouterById,
} from '../../../selectors/develop/routers';
import {request} from "../../request";
import * as api from "../../../services/api/develop/routers";
import * as notificationsActions from "../../../actions/notifications";

function normalizeNestedProperties(properties: any): any {
	return Object.keys(properties).filter((id) => !!properties[id]).map((id: any) => {
		return {
			propertyId: id,
			nestedProperties: properties[id] !== true ? normalizeNestedProperties(properties[id]) : [],
		};
	});
}

function* getRoutersData() {
	const projectId = yield select(getProjectId);

	const entitiesData = yield call(request, {
		entity: routersActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		}
	});
	const body = entitiesData.payload.body;

	if (body.success) {
		yield put(routersActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* addRouters({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		authorizationRules,
		resolve,
		reject,
	} = payload;
	const routers: any = [];

	function appendAuthorization(methods: any, authDefinition: any) {
		if (!authDefinition || !authDefinition.length) {
			return methods;
		}

		return methods.map(({name}: any) => {
			const authData = authDefinition.find(({methods}: any) => {
				return methods.find((value: any) => value.name === name);
			});

			if (!authData) {
				return methods;
			}

			let flow: any;

			if (authData && authData.identityValidations) {
				flow = {
					identityValidations: authData.identityValidations,
					dataModels: authData.dataModels && authData.dataModels.map((dataModel: any) => {
						if (!Array.isArray(dataModel.mockValues)) {
							const mockValueKeys = Object.keys(dataModel.mockValues || {});

							if (!mockValueKeys.length) {
								delete dataModel.mockValues;
							} else {
								dataModel.mockValues = mockValueKeys.map((value: string) => ({
									label: dataModel.mockValues[value],
									value,
								}));
							}
						}

						return dataModel;
					}),
					nestedProperties: authData.nestedProperties && normalizeNestedProperties(authData.nestedProperties),
				};
			}

			return {
				name,
				authorization: {
					authorizer: authData.authorization,
					isOptional: authData.authOptional,
					groups: authData.groups,
				},
				flow,
			};
		});
	}

	Object.keys(values || {}).forEach((key: string) => {
		if (values[key] === true) {
			routers.push({
				entityId: values[`${key}-id`],
				endpointName: key,
				methods: appendAuthorization(values[`${key}-methods`], authorizationRules[key]),
			});
		}
	});

	const routersData = yield call(request, {
		entity: routersActions.Types.ADD_DATA,
		callback: api.addRouter,
		params: {
			projectId,
			body: routers,
		}
	});

	const body = routersData.payload.body;

	if (body.success) {
		yield call(getRoutersData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* deleteRouter({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		resolve,
		reject,
	} = payload;

	const routersData = yield call(request, {
		entity: routersActions.Types.DELETE,
		callback: api.deleteRouter,
		params: {
			projectId,
			routerId: values,
		}
	});
	const body = routersData.payload.body;

	if (body.success) {
		yield call(getRoutersData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* deleteRouterMethod({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values: {
			routerId,
			methodId,
		},
		resolve,
		reject,
	} = payload;

	const router = Object.assign({}, yield select(getRouterById(routerId)) || {});
	const methodLocation = router.methods.findIndex(({id}: any) => id === methodId);
	router.methods = [...router.methods.slice(0, methodLocation), ...router.methods.slice(methodLocation + 1, router.methods.length)];

	const routersData = yield call(request, {
		entity: routersActions.Types.DELETE_METHOD,
		callback: api.updateRouter,
		params: {
			projectId,
			routerId,
			body: router,
		}
	});
	const body = routersData.payload.body;

	if (body.success) {
		yield call(getRoutersData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* updateRouterMethod({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values: {
			routerId,
			methodId,
			authorization,
			dataModels,
			nestedProperties,
			webhooks,
			identityValidations,
		},
		resolve,
		reject,
	} = payload;

	let flow:any;

	if (dataModels && dataModels.length) {
		flow = flow || {};
		flow.dataModels = dataModels.map((dataModel: any) => {
			const mockValueKeys = Object.keys(dataModel.mockValues || {});

			if (!mockValueKeys.length) {
				delete dataModel.mockValues;
			} else {
				dataModel.mockValues = mockValueKeys.map((value: string) => ({
					label: dataModel.mockValues[value],
					value,
				}));
			}

			return dataModel;
		});

	}

	if (webhooks && webhooks.length) {
		flow = flow || {};
		flow.webhooks = webhooks;
	}

	if (identityValidations && identityValidations.length) {
		flow = flow || {};
		flow.identityValidations = identityValidations;
	}

	if (nestedProperties && Object.keys(nestedProperties).length) {
		flow = flow || {};
		flow.nestedProperties = normalizeNestedProperties(nestedProperties);
	}

	const router = Object.assign({}, yield select(getRouterById(routerId)) || {});
	const methodLocation = router.methods.findIndex(({id}: any) => id === methodId);
	const newMethod = Object.assign({}, router.methods[methodLocation], {
		authorization,
		flow,
	});
	router.methods = [...router.methods.slice(0, methodLocation), newMethod, ...router.methods.slice(methodLocation + 1, router.methods.length)];

	const routersData = yield call(request, {
		entity: routersActions.Types.UPDATE_METHOD,
		callback: api.updateRouter,
		params: {
			projectId,
			routerId,
			body: router,
		}
	});
	const body = routersData.payload.body;

	if (body.success) {
		yield call(getRoutersData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchAddRoutersData() {
	yield takeLatest([routersActions.Types.ADD_DATA], addRouters);
}

export function* watchGetRoutersData() {
	yield takeLatest([routersActions.Types.LOAD_DATA], getRoutersData);
}

export function* watchDeleteRouter() {
	yield takeLatest([routersActions.Types.DELETE], deleteRouter);
}

export function* watchDeleteRouterMethod() {
	yield takeLatest([routersActions.Types.DELETE_METHOD], deleteRouterMethod);
}

export function* watchUpdateRouterMethod() {
	yield takeLatest([routersActions.Types.UPDATE_METHOD], updateRouterMethod);
}
