import React from 'react';
import {Field} from 'redux-form';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';

const styles: any = (theme: any) => ({
	formControl: {
		width: '100%',
		marginTop: 8,
		height: 64,
	},
	textField: {
		marginTop: 4,
		marginBottom: 0,
	},
});

export const renderSelectComponent = ({
	id,
	name,
	label,
	input,
	meta = {},
	options,
	classes = {},
	InputLabelProps = {},
	...rest
}: any) => (
	<FormControl error={meta.touched && meta.invalid} className={classes.formControl}>
		{label && (
			<InputLabel htmlFor={id} {...InputLabelProps}>{label}</InputLabel>
		)}
		<Select
			displayEmpty
			inputProps={{
				id,
				name,
			}}
			{...input}
			{...rest}
		>
			{options.map((option: any) => (
				<MenuItem
					key={option.value}
					{...option}>
					{option.label}
				</MenuItem>
			))}
		</Select>
		{meta.touched && meta.invalid && meta.error && (
			<FormHelperText>{meta.error}</FormHelperText>
		)}
	</FormControl>
);

export const SelectComponent = withStyles(styles, {withTheme: true})(renderSelectComponent);

export default withStyles(
	styles,
	{
		withTheme: true,
	},
)(({
	id,
	name,
	input,
	label,
	...rest
}: any) => (
	<Field
		name={name}
		label={label}
		component={renderSelectComponent}
		{...rest}
	/>
));
