import {createAction} from 'redux-actions';

export enum Types {
	SET_ACTIVE_ENVIRONMENT = 'CMS_SET_ACTIVE_ENVIRONMENT',
	LOAD_ENVIRONMENT_BUILD_DEFINITION = 'CMS_LOAD_ENVIRONMENT_BUILD_DEFINITION',
	LOAD_ENVIRONMENT_BUILD_DEFINITION_RECEIVED = 'CMS_LOAD_ENVIRONMENT_BUILD_DEFINITION_RECEIVED',
	SET_ACTIVE_ENTITY = 'CMS_SET_ENTITY',
	SET_ACTIVE_ENTITY_ROUTE = 'CMS_SET_ACTIVE_ENTITY_ROUTE',
	LOAD_ENTITIES = 'CMS_LOAD_ENTITIES',
	LOAD_ENTITIES_RECEIVED = 'CMS_LOAD_ENTITIES+RECEIVED',
	LOAD_ENTITY_DATA = 'CMS_LOAD_ENTITY_DATA',
	LOAD_ENTITY_DATA_RECEIVED = 'CMS_LOAD_ENTITY_DATA_RECEIVED',
	SET_ENTITY_SORT = 'SET_ENTITY_SORT',
	SET_ENTITY_FILTERS = 'SET_ENTITY_FILTERS',
	UPDATE_ENTITY_FILTERS = 'CMS_UPATE_ENTITY_FILTERS',
	VIEW_REFERENCE_DETAILS = 'CMS_VIEW_REFERENCE_DETAILS',
	SET_ENTITY_PAGINATION_ROWS_PER_PAGE = 'CMS_SET_ENTITY_PAGINATION_ROWS_PER_PAGE',
	SET_ENTITY_PAGINATION_CURRENT_PAGE = 'CMS_SET_ENTITY_PAGINATION_CURRENT_PAGE',
	SET_ENTITY_HIDDEN_COLUMNS = 'CMS_SET_ENTITY_HIDDEN_COLUMNS',
	SET_ENTITY_SEARCH_TEXT = 'CMS_SET_ENTITY_SEARCH_TEXT',
	VIEW_ARRAY_OF_DETAILS = 'CMS_VIEW_ARRAY_OF_DETAILS',
	CHECK_AUTHORIZATION_CREDENTIALS = 'CMS_CHECK_AUTHORIZATION_CREDENTIALS',
	CHECK_AUTHORIZATION_CREDENTIALS_RECEIVED = 'CMS_CHECK_AUTHORIZATION_CREDENTIALS_RECEIVED',
	CHECK_AUTHORIZATION_CREDENTIALS_LOGIN = 'CMS_CHECK_AUTHORIZATION_CREDENTIALS_LOGIN',
}

export const setActiveEnvironment = createAction(Types.SET_ACTIVE_ENVIRONMENT);
export const loadEnvironmentBuildDefinition = createAction(Types.LOAD_ENVIRONMENT_BUILD_DEFINITION);
export const loadEnvironmentBuildDefinitionReceived = createAction(Types.LOAD_ENVIRONMENT_BUILD_DEFINITION_RECEIVED);
export const setActiveEntity = createAction(Types.SET_ACTIVE_ENTITY);
export const setActiveEntityRoute = createAction(Types.SET_ACTIVE_ENTITY_ROUTE);
export const loadEntities = createAction(Types.LOAD_ENTITIES);
export const loadEntitiesReceived = createAction(Types.LOAD_ENTITIES_RECEIVED);
export const loadEntityData = createAction(Types.LOAD_ENTITY_DATA);
export const loadEntityDataReceived = createAction(Types.LOAD_ENTITY_DATA_RECEIVED);
export const setEntitySort = createAction(Types.SET_ENTITY_SORT);
export const setEntityFilters = createAction(Types.SET_ENTITY_FILTERS);
export const updateEntityFilters = createAction(Types.UPDATE_ENTITY_FILTERS);
export const viewReferenceDetails = createAction(Types.VIEW_REFERENCE_DETAILS);
export const viewArrayOfDetails = createAction(Types.VIEW_ARRAY_OF_DETAILS);
export const setEntityPaginationRowsPerPage = createAction(Types.SET_ENTITY_PAGINATION_ROWS_PER_PAGE);
export const setEntityPaginationCurrentPage = createAction(Types.SET_ENTITY_PAGINATION_CURRENT_PAGE);
export const setEntityHiddenColumns = createAction(Types.SET_ENTITY_HIDDEN_COLUMNS);
export const setSearchText = createAction(Types.SET_ENTITY_SEARCH_TEXT);
export const checkAuthorizationCredentials = createAction(Types.CHECK_AUTHORIZATION_CREDENTIALS);
export const checkAuthorizationCredentialsReceived = createAction(Types.CHECK_AUTHORIZATION_CREDENTIALS_RECEIVED);
