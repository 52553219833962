import request, {
	bodyParser,
	getDefaultHeaders,
} from '../request';

export async function getAll({projectId}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/databases`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addDatabase({projectId, body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/databases`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function updateDatabase({projectId, body, databaseId}: any) {
	return request.patch({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/databases/${databaseId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function deleteDatabase({projectId, databaseId}: any) {
	return request.delete({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/databases/${databaseId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
