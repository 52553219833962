import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'DEVELOP_ROUTERS_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'DEVELOP_ROUTERS_LOAD_DATA_RECEIVED',
	UPDATE_DATA = 'DEVELOP_ROUTERS_UPDATE_DATA',
	ADD_DATA = 'DEVELOP_ROUTERS_ADD_DATA',
	SOFT_ADD_DATA = 'DEVELOP_ROUTERS_SOFT_ADD_DATA',
	DELETE = 'DEVELOP_ROUTERS_DELETE',
	DELETE_METHOD = 'DEVELOP_ROUTERS_DELETE_METHOD',
	UPDATE_METHOD = 'DEVELOP_ROUTERS_UPDATE_METHOD',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const updateData = createAction(Types.UPDATE_DATA);
export const addData = createAction(Types.ADD_DATA);
export const softAddData = createAction(Types.SOFT_ADD_DATA);
export const deleteRouter = createAction(Types.DELETE);
export const deleteRouterMethod = createAction(Types.DELETE_METHOD);
export const updateRouterMethod = createAction(Types.UPDATE_METHOD);
