import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as environmentVariablesActions from '../../../actions/deploy/environmentVariables';
import {
	getProjectId,
} from '../../../selectors/router';
import {request} from "../../request";
import * as api from "../../../services/api/deploy/environmentVariables";
import * as notificationsActions from "../../../actions/notifications";

function* getEnvironmentVariablesData() {
	const projectId = yield select(getProjectId);

	const environmentVariablesData = yield call(request, {
		entity: environmentVariablesActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		}
	});
	const body = environmentVariablesData.payload.body;

	if (body.success) {
		yield put(environmentVariablesActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

export function* watchGetEnvironmentVariablesData() {
	yield takeLatest([environmentVariablesActions.Types.LOAD_DATA], getEnvironmentVariablesData);
}
