import { call, cancelled as cancelledSaga, put } from 'redux-saga/effects';
import { RequestInterface } from '../interfaces/Request';
import * as requestsActions from '../actions/requests';

export function* request(requestData: RequestInterface) {
	const {
		entity,
		callback,
		params,
	} = requestData;

	// Put the started type.
	yield put(requestsActions.start(requestData));

	try {
		// Attempt to call the promise.
		const body = yield call(callback as any, params);

		// If it's successful put the succeeded type.
		return yield put(requestsActions.succeeded({
			entity,
			body,
		} as RequestInterface));
	} catch (e) {
		// If it's unsuccessful put the errored type.
		// eslint-disable-next-line no-console
		if (e.response) {
			let body = e.response.body;

			if (body) {
				try {
					body = JSON.parse(body);
				} catch (e) {}

				return yield put(requestsActions.errored({
					entity,
					body,
				} as RequestInterface));
			}
		}
	} finally {
		if (yield cancelledSaga()) {
			// If this saga is cancelled put the cancelled type.
			yield put(requestsActions.canceled(requestData));
		}
	}
}
