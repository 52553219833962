import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getDevelopersList({
	projectId,
}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_PROJECT_URL}/projects/${projectId}/users`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addDeveloper({
	projectId,
	email,
	gitUser,
	role,
}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_PROJECT_URL}/projects/${projectId}/users`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify({
			email,
			gitUser,
			role,
		}),
	})
		.then(bodyParser);
}

export async function removeDeveloper({
	projectId,
	developerId,
}: any) {
	return request.delete({
		uri: `${process.env.REACT_APP_API_PROJECT_URL}/projects/${projectId}/users/${developerId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
