import React from 'react';
import CheckboxField from '../../../framework/fields/Checkbox';
import FormGroup from '@material-ui/core/FormGroup';
import {makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import TextField from "../../../framework/fields/Text";
import SelectField from "../../../framework/fields/Select";
import TagsField from '../../../framework/fields/Tags';

export const validationsOptions: any = {
	'string': [
		'required',
		'contains',
		'containsOptions',
		'isIn',
		'isInOptions',
		'isNotIn',
		'isNotInOptions',
		'minMaxLength',
		'minMaxLengthMinOption',
		'minMaxLengthMaxOption',
		'matches',
		'matchesPredefined',
		'matchesRegexp',
		'matchesRegexpErrorMessage',
	],
	'number': [
		'required',
		'isIn',
		'isInOptions',
		'isNotIn',
		'isNotInOptions',
		'isPositive',
		'isNegative',
		'minMax',
		'minMaxMinOption',
		'minMaxMaxOption',
		'matches',
		'matchesPredefined',
		'matchesRegexp',
		'matchesRegexpErrorMessage',
	],
	'Date': [
		'required',
		'minMaxDate',
		'minMaxDateMinOption',
		'minMaxDateMaxOption',
		'matches',
		'matchesPredefined',
		'matchesRegexp',
		'matchesRegexpErrorMessage',
	],
	'arrayOf': [
		'required',
	],
	'object': [
		'required',
	],
	'reference': [
		'required',
	],
};

const matchesOptions:any = {
	'string': [
		{
			value: 'none',
			label: 'None',
			regexp: ``,
		},
		{
			value: 'email',
			label: 'Email',
			regexp: `/^\\w[\\w.-]*@([\\w-]+\\.)+[\\w-]+$/gi`,
		},
		{
			value: 'url',
			label: 'URL',
			regexp: `/^(ftp|http|https):\\/\\/(\\w+:{0,1}\\w*@)?(\\S+)(:[0-9]+)?(\\/|\\/([\\w#!:.?+=&%@!\\-\\/]))?$/gi`,
		}
	],
	'Date': [
		{
			value: 'none',
			label: 'None',
			regexp: ``,
		},
		{
			value: 'dateUs',
			label: 'US Date',
			regexp: `/^(0?[1-9]|1[012])[- \\/.](0?[1-9]|[12][0-9]|3[01])[- \\/.](19|20)?\\d\\d$/gi`,
		},
		{
			value: 'dateEu',
			label: 'EU Date',
			regexp: `/^(0?[1-9]|[12][0-9]|3[01])[- \\/.](0?[1-9]|1[012])[- \\/.](19|20)?\\d\\d$/gi`,
		},
		{
			value: '12time',
			label: '12h Time',
			regexp: `/^(0?[1-9]|1[012]):[0-5][0-9](:[0-5][0-9])?\\s*[aApP][mM]$/gi`,
		},
		{
			value: '24time',
			label: '24h Time',
			regexp: `/^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/gi`,
		}
	],
};

const useStyles = makeStyles({
	formGroup: {
		marginBottom: 10,
		fontSize: '0.8rem',
		color: '#728498',
	},
	formGroupDescription: {
		padding: 0,
		marginTop: -8,
		marginLeft: 22,
	},
	formLabel: {
		fontSize: '0.8rem',
		fontWeight: 'bold',
		color: '#0a3c62',
	},
	checkbox: {
		padding: 4,
		margin: 0,
	},
});

const EntityPropertyMockForm = (props: any) => {
	const classes = useStyles();
	const propertyType = props.formValues.type;
	const propertySubtype = props.formValues.subtype;
	const isNotInOptions = props.isNotInOptions;
	const isInOptions = props.isInOptions;
	const allowedValidations = validationsOptions[propertyType] || [];
	const matchPredefinedOptions = matchesOptions[propertyType];

	function onRegularExpressionChange(e: any, newValue: string,) {
		const definedRegex = (matchesOptions[propertyType] || []).find(({value}: any) => value === newValue) || {};
		props.changeFieldValue('validators.matchesRegexp', definedRegex.regexp);
	}

	return (
		<>
			{allowedValidations.includes('required') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.required"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Required Field</div>}
					/>
					<span className={classes.formGroupDescription}>You won't be able to add any entities having this field empty</span>
				</FormGroup>
			)}
			{allowedValidations.includes('contains') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.contains"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Contains specific text</div>}
					/>
					<span className={classes.formGroupDescription}>Specify a text which all of your values needs to contains. Eg: A prefix.</span>
					{!!props.formValues.validators.contains && (
						<TextField
							margin="normal"
							fullWidth
							required
							id="containsOptions"
							label="Allowed options"
							name="validators.containsOptions"
							placeholder="Eg: AB-"
							InputLabelProps={{
								shrink: true,
							}}
						/>
					)}
				</FormGroup>
			)}
			{allowedValidations.includes('isIn') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						disabled={!!isNotInOptions}
						name="validators.isIn"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Accept only specific values</div>}
					/>
					<span className={classes.formGroupDescription}>Specify a set of values allowed to be added on this field</span>
					{!!props.formValues.validators.isIn && (
						<TagsField
							margin="normal"
							fullWidth
							required
							id="isInOptions"
							label="Allowed options"
							name="validators.isInOptions"
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								placeholder: 'Add new option'
							}}
							updateValue={props.changeFieldValue}
						/>
					)}
				</FormGroup>
			)}
			{allowedValidations.includes('isNotIn') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						disabled={!!isInOptions}
						name="validators.isNotIn"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Block some specific values</div>}
					/>
					<span className={classes.formGroupDescription}>Specify a set of values which are not allowed to be added on this field</span>
					{!!props.formValues.validators.isNotIn && (
						<TagsField
							margin="normal"
							fullWidth
							required
							id="isNotInOptions"
							label="Allowed options"
							name="validators.isNotInOptions"
							InputLabelProps={{
								shrink: true,
							}}
							InputProps={{
								placeholder: 'Add new option'
							}}
							updateValue={props.changeFieldValue}
						/>
					)}
				</FormGroup>
			)}
			{allowedValidations.includes('isPositive') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.isPositive"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Is positive number</div>}
					/>
					<span className={classes.formGroupDescription}>Mark this field to accept only positive numbers</span>
				</FormGroup>
			)}
			{allowedValidations.includes('isNegative') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.isNegative"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Is negative number</div>}
					/>
					<span className={classes.formGroupDescription}>Mark this field to accept only negative numbers</span>
				</FormGroup>
			)}
			{allowedValidations.includes('minMax') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.minMax"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Accept only specific number range</div>}
					/>
					<span className={classes.formGroupDescription}>Specify a minimum and/or maximum number allowed for this field</span>
					{!!props.formValues.validators.minMax && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									type="number"
									fullWidth
									id="minMaxMinOption"
									label="Minimum Value"
									name="validators.minMaxMinOption"
									placeholder="Eg: 5"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									type="number"
									fullWidth
									id="minMaxMaxOption"
									label="Maximum Value"
									name="validators.minMaxMaxOption"
									placeholder="Eg: 100"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					)}
				</FormGroup>
			)}
			{allowedValidations.includes('minMaxDate') && (['date', 'datetime'].includes(propertySubtype)) && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.minMaxDate"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Accept only specific date range</div>}
					/>
					<span className={classes.formGroupDescription}>Specify a minimum and/or maximum date allowed for this field</span>
					{!!props.formValues.validators.minMaxDate && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									fullWidth
									type={(propertySubtype === 'date' ? 'date' : (
										propertySubtype === 'datetime' ? 'datetime-local' : 'text'
									))}
									id="minMaxDateMinOption"
									label="Start Date"
									name="validators.minMaxDateMinOption"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									fullWidth
									type={(propertySubtype === 'date' ? 'date' : (
										propertySubtype === 'datetime' ? 'datetime-local' : 'text'
									))}
									id="minMaxDateMaxOption"
									label="End Date"
									name="validators.minMaxDateMaxOption"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					)}
				</FormGroup>
			)}
			{allowedValidations.includes('minMaxDate') && (['time'].includes(propertySubtype)) && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.minMaxDate"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Accept only specific time range</div>}
					/>
					<span className={classes.formGroupDescription}>Specify a minimum and/or maximum time allowed for this field</span>
					{!!props.formValues.validators.minMaxDate && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									fullWidth
									type="time"
									id="minMaxDateMinOption"
									label="Start Date"
									name="validators.minMaxDateMinOption"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									fullWidth
									type="time"
									id="minMaxDateMaxOption"
									label="End Date"
									name="validators.minMaxDateMaxOption"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					)}
				</FormGroup>
			)}
			{allowedValidations.includes('minMaxLength') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.minMaxLength"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Limit character count</div>}
					/>
					<span className={classes.formGroupDescription}>Specify a minimum and/or maximum number of characters allowed for this field</span>
					{!!props.formValues.validators.minMaxLength && (
						<Grid container spacing={2}>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									type="number"
									fullWidth
									id="minMaxLengthMinOption"
									label="Minimum Length"
									name="validators.minMaxLengthMinOption"
									placeholder="Eg: 5"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item xs={6}>
								<TextField
									margin="normal"
									type="number"
									fullWidth
									id="minMaxLengthMaxOption"
									label="Maximum Length"
									name="validators.minMaxLengthMaxOption"
									placeholder="Eg: 100"
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
						</Grid>
					)}
				</FormGroup>
			)}
			{allowedValidations.includes('matches') && (
				<FormGroup className={classes.formGroup}>
					<CheckboxField
						name="validators.matches"
						className={classes.checkbox}
						label={<div className={classes.formLabel}>Match a specific pattern</div>}
					/>
					<span className={classes.formGroupDescription}>Make this field match a pattern: email address, URL or custom regular expression</span>
					{!!props.formValues.validators.matches && (
						<>
							<Grid container spacing={2}>
								{!!matchPredefinedOptions && (
									<Grid item xs={3}>
										<SelectField
											required
											name="validators.matchesPredefined"
											label="Predefined"
											onChange={onRegularExpressionChange}
											options={matchPredefinedOptions}
										/>
									</Grid>
								)}
								<Grid item xs={!!matchPredefinedOptions ? 9 : 12}>
									<TextField
										margin="normal"
										fullWidth
										id="matchesRegexp"
										label="Regular Expression"
										name="validators.matchesRegexp"
										placeholder="Eg: /ab+c/"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
							</Grid>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<TextField
										margin="normal"
										fullWidth
										id="matchesRegexpErrorMessage"
										label="Custom error message"
										name="validators.matchesRegexpErrorMessage"
										placeholder="Eg: Should be a valid email address"
										InputLabelProps={{
											shrink: true,
										}}
									/>
								</Grid>
							</Grid>
						</>
					)}
				</FormGroup>
			)}
		</>
	);
};

export default EntityPropertyMockForm;
