import {createAction} from 'redux-actions';

export enum Types {
	LOGIN_USER = 'ACCOUNT_LOGIN_USER',
	REGISTER_USER = 'ACCOUNT_REGISTER_USER',
	REGISTER_CONFIRM = 'ACCOUNT_REGISTER_CONFIRM',
	RESEND_VERIFICATION_CODE = 'ACCOUNT_RRESEND_VERIFICATION_CODE',
	PASSWORD_RECOVER = 'ACCOUNT_PASSWORD_RECOVER',
	PASSWORD_RECOVER_CONFIRM = 'PASSWORD_RECOVER_CONFIRM',
}

export const loginUser = createAction(Types.LOGIN_USER);
export const registerUser = createAction(Types.REGISTER_USER);
export const registerConfirm = createAction(Types.REGISTER_CONFIRM);
export const resendVerificationCode = createAction(Types.RESEND_VERIFICATION_CODE);
export const passwordRecover = createAction(Types.PASSWORD_RECOVER);
export const passwordRecoverConfirm = createAction(Types.PASSWORD_RECOVER_CONFIRM);
