import path from '../../utils/pathSelector';
import {ReduxRootStateInterface} from "../../interfaces/Redux";
import {getRequestsByName} from "../../utils/requests";
import {Types as EnvironmentVariablesActionTypes} from "../../actions/deploy/environmentVariables";

export const getBoilerplateEnvironmentVariablesData = path(['environmentVariables', 'boilerplate']);
export const getZemuzCloudEnvironmentVariablesData = path(['environmentVariables', 'zemuzCloud']);
export const getDockerEnvironmentVariablesData = path(['environmentVariables', 'docker']);

export const isGetEnvironmentVariablesLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EnvironmentVariablesActionTypes.LOAD_DATA);
};
