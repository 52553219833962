import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as codeReviewActions from '../../../actions/sourceCode/codeReview';
import * as projectStructureActions from '../../../actions/sourceCode/projectStructure';
import * as sourceCodeActions from '../../../actions/sourceCode/sourceCode';
import {
	getProjectId,
} from '../../../selectors/router';
import {
	getProjectById,
} from '../../../selectors/projects';
import {
	getBuildById,
} from '../../../selectors/build/builds';
import {
	getActiveBuild,
	getCompareToBuild,
	getEditorSourceCode,
} from '../../../selectors/sourceCode/codeReview';
import {request} from '../../request';
import * as api from '../../../services/api/sourceCode/sourceCode';
import * as changeCase from 'change-case';
import * as notificationsActions from '../../../actions/notifications';
import normalizeProjectStructure from '../../../utils/sourceCode/normalizeProjectStructure';

function* fetchProjectStructure() {
	const projectId = yield select(getProjectId);
	const project = yield select(getProjectById(projectId));
	const activeBuilds = yield select(getActiveBuild);
	const compareToBuild = yield select(getCompareToBuild);
	const buildId = activeBuilds.id;
	const compareToBuildId = compareToBuild && compareToBuild.id;

	const projectStructureData = yield call(request, {
		entity: projectStructureActions.Types.LOAD_DATA,
		callback: api.getProjectStructure,
		params: {
			projectId,
			buildId,
			compareToBuildId,
			projectName: changeCase.pascalCase(project.name),
		},
	});

	const body = projectStructureData.payload.body;

	if (body.success) {
		yield put(projectStructureActions.loadDataReceived({
			data: {
				buildId,
				compareToBuildId,
				fileStructure: normalizeProjectStructure(body.data.projectStructure),
			},
		}));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* fetchSourceCode({payload: {
		fullPath,
	}}: any) {
	const sourceCode = yield select(getEditorSourceCode);

	if (!sourceCode) {
		const projectId = yield select(getProjectId);
		const project = yield select(getProjectById(projectId));
		const activeBuild = yield select(getActiveBuild);
		const compareToBuild = yield select(getCompareToBuild);
		const buildId = activeBuild.id;
		const compareToBuildId = compareToBuild && compareToBuild.id;

		const sourceCodeData = yield call(request, {
			entity: sourceCodeActions.Types.LOAD_DATA,
			callback: api.getSourceCode,
			params: {
				projectId,
				buildId,
				compareToBuildId,
				projectName: changeCase.pascalCase(project.name),
				filePath: btoa(fullPath),
			},
		});

		const body = sourceCodeData.payload.body;

		if (body.success) {
			yield put(sourceCodeActions.loadDataReceived({
				data: {
					pagePath: fullPath,
					sourceCode: body.data.fileContent,
					compareToSourceCode: body.data.compareToFileContent,
				},
			}));
		} else {
			yield put(notificationsActions.addError({
				message: body.error.message || body.error.name,
			}));
		}
	}
}

function* setActiveBuild({payload}: any) {
	const {
		values: {
			buildId,
			compareToBuildId,
		},
		resolve,
	} = payload;

	const build = yield select(getBuildById(buildId));
	let compareToBuild = null;
	if (compareToBuildId) {
		compareToBuild = yield select(getBuildById(compareToBuildId));
	}

	yield put(codeReviewActions.setBuildReceived({
		data: {
			build,
			compareToBuild,
		},
	}));

	yield call(fetchProjectStructure);

	resolve && resolve();
}

export function* watchSetActiveBuild() {
	yield takeLatest([codeReviewActions.Types.SET_BUILD], setActiveBuild);
}

export function* watchFetchSourceCode() {
	yield takeLatest([sourceCodeActions.Types.LOAD_DATA], fetchSourceCode);
}
