import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {PluginSettingsReduxStateInterface} from '../../interfaces/plugins/PluginSettings';
import * as PluginsSettingsActions from '../../actions/plugins/settings';

const initialData: PluginSettingsReduxStateInterface = {
	list: List(),
};

const PluginsSettingsData = Record(initialData);

export default handleActions<any, any>(
	{
		[PluginsSettingsActions.Types.LOAD_DATA]: (state) => {
			return state
				.set('list', List());
		},
		[PluginsSettingsActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			const {
				payload: {
					data,
				},
			} = action;

			return state
				.set('list', List(data));
		},
	},
	new PluginsSettingsData(),
);
