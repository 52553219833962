import React from 'react';
import {Route, Redirect} from 'react-router-dom';

export function getDefaultMethodName(name: string) {
	switch (name) {
		case 'getAll':
		case 'getById':
			return 'GET';
		case 'add':
		case 'getFilteredData':
			return 'POST';
		case 'remove':
			return 'DELETE';
		case 'update':
			return 'PUT';
		case 'patch':
			return 'PATCH';
		default:
			break;
	}
}

export function ProtectedRoute({ component: Component, isLoggedIn, ...rest }: any) {
	const [loggedIn, setLoggedIn] = React.useState(null);

	isLoggedIn.then((state: any) => {
		setLoggedIn(state);
	});

	return (
		<Route
			{...rest}
			render={ (props) =>
				loggedIn === null ? (
					<></>
				) : (
					loggedIn === true ? (
				<Component {...props} />
					) : (
						<Redirect
							to={{
								pathname: '/login',
								state: { from: props.location },
							}}
						/>
					)
				)
			}
		/>
	);
}

export function PublicRoute({ component: Component, isLoggedIn, blockWhenLoggedIn, ...rest }: any) {
	const [loggedIn, setLoggedIn] = React.useState(null);

	isLoggedIn.then((state: any) => {
		setLoggedIn(state);
	});

	return (
		<Route
			{...rest}
			render={ (props) =>
				(blockWhenLoggedIn && loggedIn === null ? (
					<></>
				) : (
					loggedIn === true ? (
						<Redirect
							to={{
								pathname: '/',
								state: { from: props.location },
							}}
						/>
					) : (
						<Component {...props} />
					)
				))
			}
		/>
	);
}
