import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getMergeList({
	projectId,
}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CODE_URL}/projects/${projectId}/merges/filter`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify({
			pagination: {
				limit: 100,
			},
			sorting: {
				createdAt: 'desc',
			},
		}),
	})
		.then(bodyParser);
}
