import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {RoutersReduxStateInterface} from '../../interfaces/develop/Router';
import * as RoutersActions from '../../actions/develop/routers';

const initialData: RoutersReduxStateInterface = {
	list: List(),
};

const RoutersData = Record(initialData);

export default handleActions<any, any>(
	{
		[RoutersActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			return state
				.set('list', List(action.payload.data));
		},
	},
	new RoutersData(),
);
