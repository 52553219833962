import path from '../utils/pathSelector';
import {ReduxRootStateInterface} from '../interfaces/Redux';
import {getRequestsByName} from '../utils/requests';
import {Types as ProjectsActionTypes} from "../actions/projects";
import {Types as DevelopEntitiesActionTypes} from "../actions/develop/entities";
import {Types as DevelopRoutersActionTypes} from "../actions/develop/routers";
import {Types as DeployEnvironmentsActionTypes} from "../actions/deploy/environments";
import {Types as DeployBuildsActionTypes} from "../actions/build/builds";
import {Types as DeployEnvironmentVariablesActionTypes} from "../actions/deploy/environmentVariables";
import {Types as DatabasesVariablesActionTypes} from "../actions/databases";
import {Types as AuthorizationsVariablesActionTypes} from "../actions/authorizations";
import {Types as MergesVariablesActionTypes} from "../actions/merge/merge";
import {Types as SshKeysActionTypes} from "../actions/account/sshKeys";

export const isTopLoadingBarVisible = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, [
		ProjectsActionTypes.LOAD_DATA,
		DevelopEntitiesActionTypes.LOAD_DATA,
		DevelopRoutersActionTypes.LOAD_DATA,
		DeployEnvironmentsActionTypes.LOAD_DATA,
		DeployBuildsActionTypes.LOAD_DATA,
		DeployEnvironmentVariablesActionTypes.LOAD_DATA,
		DatabasesVariablesActionTypes.LOAD_DATA,
		AuthorizationsVariablesActionTypes.LOAD_DATA,
		MergesVariablesActionTypes.LOAD_DATA,
		SshKeysActionTypes.LOAD_DATA,
	]);
};
