import {
	takeLatest,
	call,
	select,
	put,
} from 'redux-saga/effects';
import * as cmsActions from '../../../actions/cms/cms';
import {request} from '../../request';
import * as api from '../../../services/api/environmentBuildConfig/environmentBuildConfig';
import * as cmsApi from '../../../services/api/cms/cms';
import {getProjectId} from '../../../selectors/router';
import { getProjectById } from '../../../selectors/projects';
import {
	getActiveEnvironment,
	getActiveEntityRoute,
	getEntityDataList,
	getEntityDataSort,
	getEntityDataFilters,
	getEntityDataPagination,
	getAuthorizers,
} from '../../../selectors/cms/cms';
import * as notificationsActions from '../../../actions/notifications';

function* fetchEnvironmentBuildConfig({payload}: any) {
	const {
		environment,
		environment: {
			build: {
				id,
			},
		},
		resolve,
		reject,
	} = payload;
	const projectId = yield select(getProjectId);
	const project = yield select(getProjectById(projectId));
	const projectName = project.id;

	function normalizeBuildConfiguration({
		configuration: {
			models,
			routers,
			projectFile,
			zemuzCompose,
		},
	}: any) {
		return Object.keys(routers || {}).reduce((reduced: any, routerName: string) => {
			const endpoints = routers[routerName].endpoints;

			const filterEndpoints = Object.keys(endpoints || {})
				.map((endpointName: string) => {
					const endpointDefinition = endpoints[endpointName];

					const [requiredMethod, authorizer] = endpointDefinition.methods.filter(({engineMethod}: any) => {
						return ['getFilteredData', 'getAll'].includes(engineMethod);
					}).map(({
						engineMethod,
						authorizer,
					}: any) => [engineMethod, authorizer])[0] || [] as any;

					if (!!requiredMethod && !endpointDefinition.params) {
						return {
							endpointName,
							method: requiredMethod,
							params: endpointDefinition.params,
							authorization: authorizer ? {
								authorizer,
								definition: zemuzCompose['zemuz-compose'].authorizations[authorizer.name],
							} : undefined,
						};
					}

					return null;
				})
				.filter((endpoint: any) => endpoint && endpoint.method)
				.sort((a: any, b: any) => {
					if (a.method === b.method) {
						if (a.endpointName.length === b.endpointName.length) {
							return 0;
						} else if (a.endpointName.length < b.endpointName.length) {
							return -1;
						} else {
							return 1;
						}
					} else if (a.method === 'getFilteredData') {
						return -1;
					} else {
						return 1;
					}
				});

			const projectFileDefinition = projectFile[projectName];
			let modelName = null;
			if (projectFileDefinition) {
				const routerDefinition = projectFileDefinition.routers[routerName];

				if (routerDefinition) {
					const controllerDefinition = projectFileDefinition.controllers[routerDefinition.controller];

					if (controllerDefinition) {
						const daoDefinition = projectFileDefinition.daos[controllerDefinition.dao];

						if (daoDefinition) {
							modelName = daoDefinition.model;
						}
					}
				}
			}

			if (filterEndpoints && modelName) {
				reduced.push({
					routerName,
					modelName,
					modelDefinition: models[modelName],
					filterEndpoints,
					hasFilterEndpoints: !!filterEndpoints.length,
				});
			}

			return reduced;
		}, []);
	}

	const environmentBuildConfig = yield call(request, {
		entity: cmsActions.Types.LOAD_ENVIRONMENT_BUILD_DEFINITION,
		callback: api.getBuildConfig,
		params: {
			projectId,
			buildId: id,
		},
	});

	const body = environmentBuildConfig.payload.body;

	if (body.success) {
		yield put(cmsActions.loadEnvironmentBuildDefinitionReceived({
			data: {
				environmentId: environment.id,
				environmentData: normalizeBuildConfiguration(body.data[0]),
			},
		}));
		resolve && resolve();

	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* setActiveEnvironment({payload}: any) {
	const {
		data: {
			environment,
		},
		resolve,
		reject,
	} = payload;

	yield put(cmsActions.loadEnvironmentBuildDefinition({
		environment,
		resolve,
		reject,
	}));
}

function* checkAuthorizationCredentials({payload}: any) {
	const {
		data,
		resolve,
		reject,
	} = payload;
	const environment = yield select(getActiveEnvironment);

	const loginData = yield call(request, {
		entity: cmsActions.Types.CHECK_AUTHORIZATION_CREDENTIALS_LOGIN,
		callback: cmsApi.login,
		params: {
			environment,
			...data
		},
	});

	const body = loginData.payload.body;

	if (body.data) {
		yield put(cmsActions.checkAuthorizationCredentialsReceived({
			authorizationName: data.authorizationName,
			value: body.data,
		}));
		resolve && resolve();
	} else if (body.error) {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* fetchInitialEnvironmentData() {
	const existingData = yield select(getEntityDataList);

	if (!existingData.size) {
		yield call(reloadEnvironmentData);
	}
}

function* reloadEnvironmentData() {
	const environment = yield select(getActiveEnvironment);
	const authorizers = yield select(getAuthorizers);
	const entityRoute = yield select(getActiveEntityRoute);
	const sort = yield select(getEntityDataSort);
	const filters = yield select(getEntityDataFilters);
	const pagination = yield select(getEntityDataPagination);

	const hasAuthorizer = !!entityRoute.authorization;
	let authorizerName;
	let authTokens;

	if (hasAuthorizer) {
		authorizerName = entityRoute.authorization.authorizer.name;
		authTokens = authorizers.get(authorizerName);
	}

	const cmsData = yield call(request, {
		entity: cmsActions.Types.LOAD_ENTITY_DATA,
		callback: cmsApi.getCMSData,
		params: {
			environment,
			entityRoute,
			sort,
			filters,
			pagination,
			authTokens,
		},
	});

	if (cmsData) {
		const body = cmsData.payload.body;

		if (body.success) {
			yield put(cmsActions.loadEntityDataReceived({
				environmentId: environment.id,
				endpointName: entityRoute.endpointName,
				data: body.data,
				size: body.size,
			}));
		} else {
			yield put(notificationsActions.addError({
				message: body.error.message || body.error.name,
			}));
		}
	} else {
		yield put(notificationsActions.addError({
			message: 'Failed to load data!',
		}));
	}
}

export function* watchCmsSetActiveEnvironment() {
	yield takeLatest([cmsActions.Types.SET_ACTIVE_ENVIRONMENT], setActiveEnvironment);
}

export function* watchCmsFetchEnvironmentBuildConfig() {
	yield takeLatest([cmsActions.Types.LOAD_ENVIRONMENT_BUILD_DEFINITION], fetchEnvironmentBuildConfig);
}

export function* watchCmsCheckAuthorizationCreadentials() {
	yield takeLatest([cmsActions.Types.CHECK_AUTHORIZATION_CREDENTIALS], checkAuthorizationCredentials);
}

export function* watchCmsFetchEnvironmentData() {
	yield takeLatest([
		cmsActions.Types.SET_ACTIVE_ENTITY,
		cmsActions.Types.SET_ACTIVE_ENTITY_ROUTE,
	], fetchInitialEnvironmentData);
}

export function* watchCmsReloadEnvironmentData() {
	yield takeLatest([
		cmsActions.Types.SET_ENTITY_SORT,
		cmsActions.Types.SET_ENTITY_FILTERS,
		cmsActions.Types.UPDATE_ENTITY_FILTERS,
		cmsActions.Types.VIEW_REFERENCE_DETAILS,
		cmsActions.Types.SET_ENTITY_PAGINATION_ROWS_PER_PAGE,
		cmsActions.Types.SET_ENTITY_PAGINATION_CURRENT_PAGE,
	], reloadEnvironmentData);
}

