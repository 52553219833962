import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getAll({projectId}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/environments/variables/boilerplate`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
