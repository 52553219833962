import {
	call,
	takeLatest,
	put,
} from 'redux-saga/effects';
import * as accountActions from '../../../actions/account';
import * as notificationsActions from '../../../actions/notifications';
import * as api from '../../../services/api/account';
import { request } from '../../request';

function* recoverPasswordConfirm(action: any) {
	const registerData = yield call(request, {
		entity: accountActions.Types.PASSWORD_RECOVER_CONFIRM,
		callback: api.recoverPasswordConfirm,
		params: action.payload,
	});

	const payload = registerData.payload.body;

	if (payload.data) {
		window.location.href = `/login/${action.payload.email}`;
	} else if (payload.error) {
		yield put(notificationsActions.addError({
			message: payload.error.message || payload.error.PASSWORD_RECOVER_CONFIRM,
		}));
	}
}

export function* watchRecoverPasswordConfirm() {
	yield takeLatest(accountActions.Types.PASSWORD_RECOVER_CONFIRM, recoverPasswordConfirm);
}
