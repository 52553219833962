import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Field} from 'redux-form';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { WithContext as ReactTags } from 'react-tag-input';

const styles: any = (theme: any) => ({
	formControl: {
		width: '100%',
		marginTop: 4,
		minHeight: 40,
	},
	textField: {
		marginTop: '4px !important',
		marginBottom: 0,
	},
	errorMessage: {
		marginTop: 4,
	},
	tags: {
		width: '100%',
		paddingBottom: 8,

		'&:before': {
			left: 0,
			right: 0,
			bottom: 0,
			content: `"\\00a0"`,
			position: 'absolute',
			transition: 'border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
			borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
			pointerEvents: 'none',
		},
	},
	tag: {
		marginRight: 4,
		marginTop: 4,
		borderRadius: 16,
		justifyContent: 'center',
		padding: '4px 4px 4px 10px',
		backgroundColor: '#e0e0e0',
		color: '#424242',
		display: 'flex',
		alignItems: 'center',
		fontSize: '15px',
	},
	remove: {
		marginLeft: 10,
		background: 'rgb(203, 203, 204)',
		color: '#424242',
		borderRadius: '50%',
		padding: '2px 7px',
		cursor: 'pointer',
	},
	tagInput: {
		flexGrow: 2,
	},
	tagInputField: {
		border: 0,
		width: '100%',
		minHeight: 25,
		fontSize: '0.9rem',
		fontWeight: 100,
		outline: 'none',
		marginTop: 7,
		background: 'transparent',
	},
	selected: {
		display: 'flex',
		flexWrap: 'wrap',
	},
});

export const TagsFieldField = ({
  id,
	label,
	InputProps,
	input,
	input: {
  	name,
		value,
	},
	classes = {},
	meta = {},
	updateValue,
	defaultValue,
}: any) => {
	value = (value || defaultValue || []).map(({ value, label}: any) => ({
		id: value,
		text: label,
	}));

	return (
		<FormControl error={meta.touched && meta.invalid} className={classes.formControl}>
			{label && (
				<InputLabel htmlFor={id} shrink={true}>{label}</InputLabel>
			)}
			<ReactTags
				inline
				id={id}
				tags={value}
				handleDelete={(index: number) => {
					updateValue(name, [...value.slice(0, index), ...value.slice(index + 1, value.length)].map(({ id, text}: any) => ({
						value: id,
						label: text,
					})));
				}}
				handleAddition={(newItem: any) => {
					updateValue(name, [...value, newItem].map(({ id, text}: any) => ({
						value: id,
						label: text,
					})));
				}}
				allowDragDrop={false}
				placeholder={InputProps && InputProps.placeholder}
				classNames={{
					tags: classes.tags,
					tag: classes.tag,
					remove: classes.remove,
					tagInput: classes.tagInput,
					tagInputField: classes.tagInputField,
					selected: classes.selected,
				}}
			/>
			{meta.touched && meta.invalid && meta.error && (
				<FormHelperText className={classes.errorMessage}>{meta.error}</FormHelperText>
			)}
		</FormControl>
	);
};

export const TagsFieldComponent = withStyles(styles)(TagsFieldField);

export default withStyles(styles)(({
	name,
	label,
	value,
	...rest
}: any) => (
	<Field
		name={name}
		label={label}
		value={value}
		component={TagsFieldField}
		{...rest}
	/>
));
