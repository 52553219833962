import request, {
	bodyParser,
	getDefaultHeaders,
} from '../request';

export async function getAll() {
	return request({
		uri: `${process.env.REACT_APP_API_PROJECT_URL}/projects`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function updateProject({projectId, body}: any) {
	return request.patch({
		uri: `${process.env.REACT_APP_API_PROJECT_URL}/projects/${projectId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function updateGitOrigin({projectId, gitUsername, gitToken, gitRepoName, gitBranch}: any) {
	return request.patch({
		uri: `${process.env.REACT_APP_CODE_GENERATOR_BASE_URL}/projects/${projectId}/git`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify({
			gitUsername,
			gitToken,
			gitRepoName,
			gitBranch,
		}),
	})
		.then(bodyParser);
}

export async function addProject({body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_PROJECT_URL}/projects`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}
