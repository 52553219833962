import React from 'react';
import {Switch} from 'react-router-dom';
import {PublicRoute, ProtectedRoute} from './utils/routers';
import LinearProgress from './components/TopLoadingBar';
import GlobalLoadingBar from './containers/GlobalLoadingBar';
import GlobalNotificationBox from './containers/GlobalNotificationBox';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { isLoggedIn } from './utils/user';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
//import HTML5Backend from 'react-dnd-html5-backend';
//import {DndProvider} from 'react-dnd';

const ApplicationsList = React.lazy(() => import('./pages/main/ApplicationsList'));
const ApplicationsHomePage = React.lazy(() => import('./pages/main/ApplicationsHomePage'));
const Login = React.lazy(() => import('./pages/account/Login'));
const Register = React.lazy(() => import('./pages/account/Register'));
const RegisterConfirm = React.lazy(() => import('./pages/account/RegisterConfirm'));
const PasswordRecover = React.lazy(() => import('./pages/account/PasswordRecover'));
const PasswordRecoverConfirm = React.lazy(() => import('./pages/account/PasswordRecoverConfirm'));
const NotFound = React.lazy(() => import('./pages/main/NotFound'));
const CreateProject = React.lazy(() => import('./pages/main/CreateApplication'));
const AccountManagement = React.lazy(() => import('./pages/account/Management'));

const isCurrentUserLoggedIn = isLoggedIn();

const theme = createMuiTheme({
	palette: {
		primary: {
			main: '#3b6283',
			dark: '#0a3c62',
			contrastText: '#def0fe',
		},
		secondary: {
			main: '#e55039',
			dark: '#eb2f06',
			contrastText: '#fff',
		},
},
} as any);

//<DndProvider backend={HTML5Backend}>

function App() {
	return (
		<MuiThemeProvider theme={theme}>
			<MuiPickersUtilsProvider utils={MomentUtils}>
				<GlobalLoadingBar />
				<GlobalNotificationBox />
				<React.Suspense fallback={<LinearProgress />}>
					<Switch>
						<ProtectedRoute exact path='/' component={ApplicationsList} isLoggedIn={isCurrentUserLoggedIn} />
						<ProtectedRoute path='/account' component={AccountManagement} isLoggedIn={isCurrentUserLoggedIn} />
						<ProtectedRoute path='/projects/create' component={CreateProject} isLoggedIn={isCurrentUserLoggedIn} />
						<ProtectedRoute path='/projects/:projectId' component={ApplicationsHomePage} isLoggedIn={isCurrentUserLoggedIn} />
						<PublicRoute path='/login/:email' component={Login} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute path='/login' component={Login} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute path='/register/confirm/:email/:verificationCode' component={RegisterConfirm} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute path='/register/confirm/:email' component={RegisterConfirm} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute path='/register' component={Register} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute path='/password/recover/confirm/:email/:verificationCode' component={PasswordRecoverConfirm} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute path='/password/recover/confirm/:email' component={PasswordRecoverConfirm} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute path='/password/recover' component={PasswordRecover} isLoggedIn={isCurrentUserLoggedIn} blockWhenLoggedIn />
						<PublicRoute component={NotFound} isLoggedIn={isCurrentUserLoggedIn} />
					</Switch>
				</React.Suspense>
			</MuiPickersUtilsProvider>
		</MuiThemeProvider>
	);
}

export default App;
