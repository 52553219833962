import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'PLUGINS_SETTINGS_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'PLUGINS_SETTINGS_LOAD_DATA_RECEIVED',
	REMOVE_DATA = 'PLUGINS_SETTINGS_REMOVE_DATA',
	REMOVE_DATA_RECEIVED = 'PLUGINS_SETTINGS_REMOVE_DATA_RECEIVED',
	ADD_DATA = 'PLUGINS_SETTINGS_ADD_DATA',
	ADD_DATA_RECEIVED = 'PLUGINS_SETTINGS_ADD_DATA_RECEIVED',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const removeData = createAction(Types.REMOVE_DATA);
export const removeDataReceived = createAction(Types.REMOVE_DATA_RECEIVED);
export const addData = createAction(Types.ADD_DATA);
export const addDataReceived = createAction(Types.ADD_DATA_RECEIVED);
