type Diff<T extends string | number | symbol, U extends string | number | symbol> =
	({ [P in T]: P } & { [P in U]: never } & { [x: string]: never })[T];
type Omit<T, K extends keyof T> = { [P in Diff<keyof T, K>]: T[P] };

export default function omit<T extends object, K extends keyof T>(
	target: T,
	...omitKeys: K[]
): Omit<T, K> {
	return (Object.keys(target) as K[]).reduce(
		(res, key) => {
			if (!omitKeys.includes(key)) {
				(res as any)[key] = target[key];
			}
			return res;
		},
		{} as Omit<T, K>,
	);
}
