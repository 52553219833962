import path from '../../utils/pathSelector';
import {ReduxRootStateInterface} from '../../interfaces/Redux';
import {getRequestsByName} from '../../utils/requests';
import {Types as BuildsActionTypes} from '../../actions/build/builds';

export const getBuildsData = path(['builds', 'list']);

export const isGetBuildsLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, BuildsActionTypes.LOAD_DATA);
};

export const isCreateBuildLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, BuildsActionTypes.ADD_DATA) || !!getRequestsByName(inProgressRequests, BuildsActionTypes.LOAD_DATA);
};

export const getBuildById = (buildId: string) => (state: ReduxRootStateInterface) => {
	const builds = getBuildsData(state);

	return builds.find(({id}: any) => id === buildId);
};

export const isBuildInProgress = (state: ReduxRootStateInterface) => {
	const builds = getBuildsData(state);

	return !!builds.find(({ status }: any) => status === 'pending');
};
