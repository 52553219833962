import request, {
	bodyParser,
	getDefaultHeaders,
} from '../request';

export async function login(params: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/account/login`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(params),
	})
		.then(bodyParser);
}

export async function register(params: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/account/register`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(params),
	})
		.then(bodyParser);
}

export async function registerConfirm(params: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/account/register/confirm`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(params),
	})
		.then(bodyParser);
}

export async function resendVerificationCode(params: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/account/register/resend-code`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(params),
	})
		.then(bodyParser);
}

export async function recoverPassword(params: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/account/password/recover`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(params),
	})
		.then(bodyParser);
}

export async function recoverPasswordConfirm(params: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/account/password/recover/confirm`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(params),
	})
		.then(bodyParser);
}
