import {
	call,
	takeLatest,
	select,
	put,
} from 'redux-saga/effects';
import * as mergeActions from '../../../actions/merge/merge';
import {
	getProjectId,
} from '../../../selectors/router';
import {request} from '../../request';
import * as api from '../../../services/api/merge/merge';
import * as notificationsActions from '../../../actions/notifications';

function* fetchMergeList() {
	const projectId = yield select(getProjectId);

	const branchData = yield call(request, {
		entity: mergeActions.Types.LOAD_DATA,
		callback: api.getMergeList,
		params: {
			projectId,
		},
	});

	const body = branchData.payload.body;

	if (body.success) {
		yield put(mergeActions.loadDataReceived({
			data: body.data,
		}));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

export function* watchFetchMergesList() {
	yield takeLatest([mergeActions.Types.LOAD_DATA], fetchMergeList);
}
