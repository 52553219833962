import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getBranchList({
	projectId,
}: any) {
	return request({
		uri: `${process.env.REACT_APP_CODE_GENERATOR_BASE_URL}/projects/${projectId}/branches`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function mergeBranch({
	projectId,
	branch,
	message,
}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_CODE_GENERATOR_BASE_URL}/projects/${projectId}/branches/merge`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify({
			message,
			branchName: branch,
		}),
	})
		.then(bodyParser);
}

export async function rebaseBranch({
	projectId,
	buildId,
}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_CODE_GENERATOR_BASE_URL}/projects/${projectId}/branches/rebase`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify({
			buildId,
		}),
	})
		.then(bodyParser);
}
