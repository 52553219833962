import request, {
	bodyParser,
	getDefaultHeaders,
} from '../request';

export async function getAll({projectId}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/authorizations`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addAuthorization({projectId, body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/authorizations`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function updateAuthrization({projectId, body, authorizationId}: any) {
	return request.patch({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/authorizations/${authorizationId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function deleteAuhtorization({projectId, authorizationId}: any) {
	return request.delete({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/authorizations/${authorizationId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
