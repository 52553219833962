import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getAll() {
	return request({
		uri: `${process.env.REACT_APP_API_CORE_URL}/ssh-keys`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addSshKey({body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_CORE_URL}/ssh-keys`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function deleteSshKey({sshKeyId}: any) {
	return request.delete({
		uri: `${process.env.REACT_APP_API_CORE_URL}/ssh-keys/${sshKeyId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
