import { Store, createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import { History } from 'history';
import createSagaMiddleware from 'redux-saga';
import { rootReducer } from './reducers';
import {ReduxRootStateInterface} from './interfaces/Redux';
import rootSagas from './sagas';

const sagaMiddleware = createSagaMiddleware();

export function configureStore(
	history: History,
	initialState?: ReduxRootStateInterface,
): Store<ReduxRootStateInterface> {
	let middleware = compose(
		applyMiddleware(
			routerMiddleware(history),
			sagaMiddleware,
		),
	);

	if (process.env.NODE_ENV !== 'production') {
		middleware = composeWithDevTools(middleware);
	}

	const store = createStore(
		(connectRouter(history) as any)(rootReducer),
		initialState as any,
		middleware,
	) as Store<ReduxRootStateInterface>;

	sagaMiddleware.run(rootSagas);

	return store;
}
