import {
	call,
	takeLatest,
	takeEvery,
	put,
	select,
} from 'redux-saga/effects';
import * as entitiesActions from '../../../actions/develop/entities';
import * as api from '../../../services/api/develop/entities';
import {request} from '../../request';
import * as notificationsActions from "../../../actions/notifications";
import {
	getProjectId,
} from '../../../selectors/router';
import {
	getEntityById,
} from '../../../selectors/develop/entities';
import {
	getProjectById,
} from '../../../selectors/projects';
import * as changeCase from 'change-case';
import { stopSubmit } from 'redux-form';
import {apiValidationMapper} from '../../../utils/reduxForm';
import { validationsOptions } from '../../../components/develop/entities/EntityPropertyValidationForm';

function* getEntitiesData() {
	const projectId = yield select(getProjectId);

	const entitiesData = yield call(request, {
		entity: entitiesActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		},
	});
	const body = entitiesData.payload.body;

	if (body.success) {
		yield put(entitiesActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* addEntityData({payload}: any) {
	const projectId = yield select(getProjectId);
	const project = yield select(getProjectById(projectId));
	const {
		values,
		resolve,
		reject,
	} = payload;

	const entity: any = {
		projectId,
		name: changeCase.pascalCase(values.name),
		dbTableName: changeCase.snakeCase(values.dbTable).toLowerCase(),
		databaseId: values.databaseId,
		primaryKey: values.primaryKey,
		timestamps: values.timestamps,
		i18n: values.i18n,
		properties: [],
	};

	if (values.primaryKey) {
		entity.properties.push({
			name: 'Id',
			type: 'string',
			subtype: 'varchar',
			autogenerated: true,
			disabled: true,
		});
	}

	if (values.timestamps) {
		entity.properties.push({
			name: 'Created At',
			type: 'Date',
			subtype: 'datetime',
			autogenerated: true,
			fakerType: 'date',
			fakerSubType: 'recent',
			disabled: true,
		});
		entity.properties.push({
			name: 'Updated At',
			type: 'Date',
			subtype: 'datetime',
			autogenerated: true,
			fakerType: 'date',
			fakerSubType: 'recent',
			disabled: true,
		});
	}

	if (values.i18n) {
		const languages = project.languages || [];
		const hasLanguages = !!languages.length;
		const normalizedLanguages = languages.map(({value}: any) => ({
			value,
			label: value,
		}));

		entity.properties.push({
			name: 'Language',
			type: 'string',
			subtype: 'varchar',
			fakerType: "oneOf",
			fakerOptions: normalizedLanguages,
			autogenerated: false,
			appearance: "singleSelect",
			defaultValue: project.defaultLanguage,
			validators: {
				required: true,
				minMaxLength: true,
				minMaxLengthMaxOption: 2,
				minMaxLengthMinOption: 2,
				isIn: hasLanguages,
				isInOptions: normalizedLanguages,
			},
		});
	}

	const entitiesData = yield call(request, {
		entity: entitiesActions.Types.ADD_DATA,
		callback: api.addEntity,
		params: {
			projectId,
			body: entity,
		}
	});
	const body = entitiesData.payload.body;

	if (body.success) {
		yield call(getEntitiesData);
		resolve && resolve();
	} else {
		yield put(stopSubmit('EntityForm', apiValidationMapper(body)));
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* updateEntityData({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		resolve,
		reject,
	} = payload;
	const newEntityData = {
		name: values.name,
		dbTableName: values.dbTable,
	};

	const entitiesData = yield call(request, {
		entity: entitiesActions.Types.UPDATE_DATA,
		callback: api.updateEntity,
		params: {
			projectId,
			entityId: values.id,
			body: newEntityData,
		}
	});
	const body = entitiesData.payload.body;

	if (body.success) {
		yield call(getEntitiesData);
		resolve && resolve();
	} else {
		yield put(stopSubmit('EntityForm', apiValidationMapper(body)));
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* softUpdateEntityData({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values: {
			id,
			propertyId,
			...fields
		},
	} = payload;
	let body;

	if (!propertyId) {
		body = fields;
	} else {
		const entity = yield select(getEntityById(id));
		body = {
			properties: entity.properties,
		};
	}

	yield call(request, {
		entity: entitiesActions.Types.SOFT_UPDATE_DATA,
		callback: api.updateEntity,
		params: {
			projectId,
			entityId: id,
			body,
		}
	});
}

function* saveEntityProperties({payload}: any) {
	const projectId = yield select(getProjectId);
	const entityId = payload;
	const entity = yield select(getEntityById(entityId));

	const entitiesData = yield call(request, {
		entity: entitiesActions.Types.SAVE_ENTITY_PROPERTIES,
		callback: api.updateEntity,
		params: {
			projectId,
			entityId,
			body: {
				properties: entity.properties.filter((entity: any) => !!entity.name),
			},
		}
	});
	const body = entitiesData.payload.body;

	if (body.success) {
		yield call(getEntitiesData);
	} else {
		yield put(stopSubmit('EntityForm', apiValidationMapper(body)));
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* updateEntityProperty({payload: {
	values: {
		formData,
		entityId,
	},
	resolve,
}}: any) {
	const propertyType = formData.type;
	const allowedValidatos = validationsOptions[propertyType] || [];
	Object.keys(formData.validators || {}).forEach((propertyName: string) => {
		if (!allowedValidatos.includes(propertyName)) {
			delete formData.validators[propertyName];
		}
	});

	yield put(entitiesActions.setBlankPropertyValues({
		id: entityId,
		values: formData,
	}));

	if (formData.editMode) {
		resolve && resolve();
	} else {
		resolve && resolve();
		yield call(saveEntityProperties, {
			payload: entityId,
		});
	}
}

function* linkEntityProperty({payload: {
	values: {
		entityId,
		propertyId,
		referencedModelId,
		...fields
	},
}}: any) {
	const entity = yield select(getEntityById(entityId));
	const property = entity.properties.find((property: any) => property.id === propertyId);

	if (property && property.referencedModelId !== referencedModelId) {
		yield put(entitiesActions.setBlankPropertyValues({
			id: entityId,
			values: {
				id: propertyId,
				referencedModelId,
				...fields
			},
		}));
	}
}

function* removeEntityProperty({
	payload: {
		values: {
			id,
			entityId,
		},
		resolve,
	},
}: any) {
	const projectId = yield select(getProjectId);
	const entity = yield select(getEntityById(entityId));
	const property = entity.properties.find((property: any) => property.id === id);
	const editMode = property.editMode;

	if (!editMode) {
		const entitiesData = yield call(request, {
			entity: entitiesActions.Types.REMOVE_ENTITY_PROPERTY,
			callback: api.updateEntity,
			params: {
				projectId,
				entityId,
				body: {
					properties: entity.properties.filter((property: any) => property.id !== id),
				},
			}
		});
		const body = entitiesData.payload.body;

		if (body.success) {
			resolve && resolve();
			yield call(getEntitiesData);
		} else {
			resolve && resolve();
			yield put(notificationsActions.addError({
				message: body.error.message || body.error.name,
			}));
		}
	} else {
		yield put(entitiesActions.removeBlankProperty({
			id,
			entityId,
		}));
		resolve && resolve();
	}
}

function* deleteEntity({payload}: any) {
	const projectId = yield select(getProjectId);
	const {
		values,
		resolve,
		reject,
	} = payload;

	const entitiesData = yield call(request, {
		entity: entitiesActions.Types.DELETE,
		callback: api.deleteEntity,
		params: {
			projectId,
			entityId: values.id,
		}
	});
	const body = entitiesData.payload.body;

	if (body.success) {
		yield call(getEntitiesData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchGetEntitiesData() {
	yield takeLatest([entitiesActions.Types.LOAD_DATA], getEntitiesData);
}

export function* watchAddEntityData() {
	yield takeLatest([entitiesActions.Types.ADD_DATA], addEntityData);
}

export function* watchUpdateEntityData() {
	yield takeLatest([entitiesActions.Types.UPDATE_DATA], updateEntityData);
}

export function* watchSoftUpdateEntityData() {
	yield takeEvery([entitiesActions.Types.SOFT_UPDATE_DATA], softUpdateEntityData);
}

export function* watchDeleteEntity() {
	yield takeLatest([entitiesActions.Types.DELETE], deleteEntity);
}

export function* watchSaveEntityProperties() {
	yield takeLatest([entitiesActions.Types.SAVE_ENTITY_PROPERTIES], saveEntityProperties);
}

export function* watchUpdateEntityProperties() {
	yield takeLatest([entitiesActions.Types.UPDATE_ENTITY_PROPERTY], updateEntityProperty);
}

export function* watchRemoveEntityProperties() {
	yield takeLatest([entitiesActions.Types.REMOVE_ENTITY_PROPERTY], removeEntityProperty);
}

export function* watchLinkEntityProperty() {
	yield takeLatest([entitiesActions.Types.LINK_ENTITY_PROPERTY], linkEntityProperty);
}
