import {handleActions} from 'redux-actions';
import {Record, Map} from 'immutable';
import {CodeReviewReduxStateInterface} from '../../interfaces/sourceCode/CodeReview';
import * as ProjectStructureActions from '../../actions/sourceCode/projectStructure';
import * as SourceCodeActions from '../../actions/sourceCode/sourceCode';
import * as CodeReviewActions from '../../actions/sourceCode/codeReview';

const initialData: CodeReviewReduxStateInterface = {
	activeBuild: null,
	compareToBuild: null,
	sourceCode: Map(),
	projectStructure: Map(),
};

const CodeReviewData = Record(initialData);

function updateSourceCodeDetails(state: any, {
	pagePath,
	sourceCode,
	compareToSourceCode,
	activePage,
	openedPages,
}: any) {
	const activeBuild =  state.get('activeBuild');
	if (activeBuild) {
		const buildId = activeBuild.id;

		let buildDetails = state.get('sourceCode').get(buildId, Map({
			openedPageIndex: -1,
			openedPages: [],
			pages: Map(),
		}));

		if (pagePath && sourceCode) {
			buildDetails = buildDetails.set('pages', buildDetails.get('pages').merge({
				[pagePath]: {
					sourceCode,
					compareToSourceCode,
				},
			}));
			buildDetails = buildDetails.set('openedPages', buildDetails.get('openedPages').map((openedPage: any) => {
				if (openedPage.fullPath === pagePath) {
					openedPage.loading = false;
				}

				return openedPage;
			}));
		} else if (activePage || activePage === 0) {
			buildDetails = buildDetails.set('openedPageIndex', activePage);
		} else if (openedPages) {
			buildDetails = buildDetails.set('openedPages', openedPages);
		}

		return state
			.set('sourceCode', state.get('sourceCode').merge({
				[buildId]: buildDetails,
			}));
	}
}

export default handleActions<any, any>(
	{
		[CodeReviewActions.Types.SET_BUILD_RECEIVED]: (state, action) => {
			const {
				payload: {
					data: {
						build,
						compareToBuild,
					},
				},
			} = action;

			return state
				.set('activeBuild', build)
				.set('compareToBuild', compareToBuild);
		},
		[ProjectStructureActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			const {
				payload: {
					data: {
						buildId,
						fileStructure,
					},
				},
			} = action;

			return state
				.set('projectStructure', state.get('projectStructure').merge({
					[buildId]: fileStructure,
				}));
		},
		[SourceCodeActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			const {
				payload: {
					data,
				},
			} = action;

			return updateSourceCodeDetails(state, data);
		},
		[SourceCodeActions.Types.SET_ACTIVE_PAGE]: (state, action) => {
			const {
				payload,
			} = action;

			return updateSourceCodeDetails(state, {
				activePage: payload,
			});
		},
		[SourceCodeActions.Types.SET_OPENED_PAGES]: (state, action) => {
			const {
				payload,
			} = action;

			return updateSourceCodeDetails(state, {
				openedPages: payload,
			});
		},
	},
	new CodeReviewData(),
);
