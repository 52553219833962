export default function normalizeProjectStructure(projectStructure: any) {
	function appendFiles(children: any, directories: any, files: any) {
		if (directories && Object.keys(directories)) {
			Object.keys(directories).forEach((directorName: string) => {
				const directoryDefinition = directories[directorName];
				const directoryConfig = {
					name: directorName,
					isDirectory: true,
					children: [],
				};

				if (directoryDefinition.directories || directoryDefinition.files) {
					appendFiles(directoryConfig.children, directoryDefinition.directories, directoryDefinition.files);
				}

				children.push(directoryConfig);
			});
		}

		if (files && files.length) {
			files.forEach(({
				               name,
				               fullPath,
				               extension,
			               }: any) => {
				let fileType;

				switch (extension) {
					case ".json":
						fileType = 'json';
						break;
					case ".js":
						fileType = 'javascript';
						break;
					case ".ts":
						fileType = 'typescript';
						break;
					case ".sh":
						fileType = 'shell';
						break;
					case ".yaml":
					case ".yml":
						fileType = 'yaml';
						break;
					case "dockerfile":
						fileType = 'dockerfile';
						break;
					default:
						fileType = 'text';
						break;
				}

				children.push({
					name,
					fullPath,
					isDirectory: false,
					fileType,
				});
			});
		}
	}

	const root = Object.keys(projectStructure.directories)[0];
	const rootDefinition = projectStructure.directories[root];
	const data = {
		name: root,
		toggled: true,
		isDirectory: true,
		children: [],
	};

	appendFiles(data.children, rootDefinition.directories, rootDefinition.files);

	return data;
}
