import { all } from 'redux-saga/effects';
import * as watchers from './watchers';

export default function* root() {
	yield all(
		Object.keys(watchers).map((watcherName: string) => {
			return (watchers as any)[watcherName]();
		}),
	);
}
