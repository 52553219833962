import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'SETTINGS_DEVELOPERS_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'SETTINGS_DEVELOPERS_LOAD_DATA_RECEIVED',
	ADD_DEVELOPER = 'SETTINGS_DEVELOPERS_SUBMIT',
	REMOVE_DEVELOPER = 'SETTINGS_DEVELOPERS_REMOVE',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const addData = createAction(Types.ADD_DEVELOPER);
export const remove = createAction(Types.REMOVE_DEVELOPER);
