import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {BranchReduxStateInterface} from '../../interfaces/merge/Branch';
import * as BranchActions from '../../actions/merge/branch';

const initialData: BranchReduxStateInterface = {
	list: List(),
};

const BranchData = Record(initialData);

export default handleActions<any, any>(
	{
		[BranchActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			const {
				payload: {
					data,
				},
			} = action;

			return state
				.set('list', List(data));
		},
		[BranchActions.Types.MERGE_BRANCH_INVALIDATE_DATA]: (state) => {

			return state
				.set('list', List());
		},
	},
	new BranchData(),
);
