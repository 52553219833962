import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getCMSData({
	environment,
	entityRoute,
	sort,
	filters,
	pagination,
	authTokens,
}: any) {
	const {
		method,
		endpointName,
	} = entityRoute;
	const { publicUrl } = environment;
	const httpReg = RegExp('^(http|https)://');
	let apiUrl = `${publicUrl}${endpointName}`;
	if (!httpReg.test(apiUrl)) {
		apiUrl = `http://${apiUrl}`;
	}

	switch (method) {
		case "getFilteredData":
			return getFilteredData({
				apiUrl,
				sort,
				filters,
				pagination,
				authTokens,
			});
		case "getAll":
			return getAllData({
				apiUrl,
				authTokens,
			});
		default:
			return {
				payload: {
					body: {
						success: false,
					},
				},
			};
	}
}

export async function getFilteredData({
  apiUrl,
	sort,
	filters,
	pagination,
  authTokens,
}: any) {
	const params = {
		sorting: Object.keys(sort || {}).length ? sort : undefined,
		pagination: {
			limit: pagination.limit,
			offset: pagination.page * pagination.limit,
		},
		filters: Object.keys(filters || {})
			.filter((filterName: string) => {
				const {
					fieldValue,
				} = filters[filterName] || {} as any;

				return fieldValue === false || fieldValue === 0 || !!fieldValue;
			})
			.map((filterName: string) => {
				const {
					fieldType,
					fieldValue,
				} = filters[filterName];

				switch (fieldType) {
					case 'tags':
						return fieldValue.map(({value}: any) => ({
							property: filterName,
							value: `${value}`,
							op: 'LIKE',
						}));
					case 'minNumber':
					case 'maxNumber':
					case 'number':
						if (fieldValue[0] && fieldValue[1]) {
							return [{
								property: filterName,
								value: fieldValue,
								op: 'BETWEEN',
							}];
						} else if (fieldValue[0]) {
							return [{
								property: filterName,
								value: fieldValue[0],
								op: 'GTE',
							}];
						} else {
							return [{
								property: filterName,
								value: fieldValue[1],
								op: 'LTE',
							}];
						}
					case 'switch':
						return [{
							property: filterName,
							value: fieldValue,
						}];
					case 'date':
					case 'minDate':
					case 'maxDate':
						if (fieldValue[0] && fieldValue[1]) {
							return [{
								property: filterName,
								value: fieldValue,
								op: 'BETWEEN',
							}];
						} else if (fieldValue[0]) {
							return [{
								property: filterName,
								value: fieldValue[0],
								op: 'GTE',
							}];
						} else {
							return [{
								property: filterName,
								value: fieldValue[1],
								op: 'LTE',
							}];
						}
					default:
						return [{
							property: filterName,
							value: fieldValue,
						}];
				}
			}
		),
	};

	return request.post({
		uri: apiUrl,
		headers: await getDefaultHeaders(authTokens),
		resolveWithFullResponse: true,
		body: JSON.stringify(params),
	})
		.then(bodyParser);
}

export async function getAllData({
	apiUrl,
	authTokens,
}: any) {
	return request({
		uri: apiUrl,
		headers: await getDefaultHeaders(authTokens),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function login({
	environment,
  username,
  password,
  authorizationName,
}: any) {
	const { publicUrl } = environment;
	const httpReg = RegExp('^(http|https)://');
	let apiUrl = `${publicUrl}`;
	if (!httpReg.test(apiUrl)) {
		apiUrl = `http://${apiUrl}`;
	}
	apiUrl = `${apiUrl}/${authorizationName}/login`;

	return request.post({
		uri: apiUrl,
		resolveWithFullResponse: true,
		body: JSON.stringify({
			username,
			password,
		}),
	})
		.then(bodyParser);
}
