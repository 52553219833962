import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {MergeReduxStateInterface} from '../../interfaces/merge/Merge';
import * as MergeActions from '../../actions/merge/merge';

const initialData: MergeReduxStateInterface = {
	list: List(),
};

const MergeData = Record(initialData);

export default handleActions<any, any>(
	{
		[MergeActions.Types.LOAD_DATA]: (state) => {
			return state
				.set('list', List());
		},
		[MergeActions.Types.INVALIDATE_DATA]: (state) => {
			return state
				.set('list', List());
		},
		[MergeActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			const {
				payload: {
					data,
				},
			} = action;

			return state
				.set('list', List(data));
		},
	},
	new MergeData(),
);
