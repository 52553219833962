import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {NotificationsReduxStateInterface} from '../interfaces/Notification';
import * as NotificationsActions from '../actions/notifications';
import uuid from 'uuid';

const initialData: NotificationsReduxStateInterface = {
	list: List(),
};

const NotificationsData = Record(initialData);

export default handleActions<any, any>(
	{
		[NotificationsActions.Types.ADD_ERROR]: (state: any, action: any) => {
			return state.set('list', state.get('list').push({
				type: 'error',
				id: uuid(),
				message: action.payload.message,
			}));
		},
		[NotificationsActions.Types.ADD_SUCCESS]: (state: any, action: any) => {
			return state.set('list', state.get('list').push({
				type: 'success',
				id: uuid(),
				message: action.payload.message,
			}));
		},
		[NotificationsActions.Types.ADD_WARNING]: (state: any, action: any) => {
			return state.set('list', state.get('list').push({
				type: 'warning',
				id: uuid(),
				message: action.payload.message,
			}));
		},
		[NotificationsActions.Types.ADD_INFO]: (state: any, action: any) => {
			return state.set('list', state.get('list').push({
				type: 'info',
				id: uuid(),
				message: action.payload.message,
			}));
		},
		[NotificationsActions.Types.REMOVE]: (state: any, action: any) => {
			return state.set('list', state.get('list').filter((item: any) => item.id !== action.payload));
		},
	},
	new NotificationsData(),
);
