import React from 'react';
import {withStyles} from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';

const styles: any = {
	topProgress: {
		zIndex: 10000,
		width: '100%',
		position: 'absolute',
		top: 0,
		left: 0,
	},
};

function TopLoadingBar(props: any) {
	const { classes } = props;

	return <LinearProgress className={classes.topProgress}/>;
}

export default withStyles(styles)(TopLoadingBar);
