import request from 'request-promise';
import { getAuthTokens } from '../utils/user';

export const bodyParser = ({ body }: any) => {
	try {
		return JSON.parse(body);
	} catch (e) {
		return body;
	}
};

export const getDefaultHeaders = async (tokens?: any) => {
	const headers: any = {
		'Content-Type': 'application/json',
	};

	if (!tokens) {
		tokens = await getAuthTokens();
	}

	if (tokens && tokens.accessToken) {
		headers['Authorization'] = tokens.accessToken;
		headers['IdToken'] = tokens.idToken;
	} else if (tokens && tokens.username) {
		headers['Authorization'] = `Basic ${btoa(`${tokens.username}:${tokens.password}`)}`;
	}

	return headers;
};

export default request;
