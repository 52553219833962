import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'SOURCE_CODE_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'SOURCE_CODE_LOAD_DATA_RECEIVED',
	SET_OPENED_PAGES = 'SOURCE_CODE_SET_OPENED_PAGES',
	SET_ACTIVE_PAGE = 'SOURCE_CODE_SET_ACTIVE_PAGE',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const setOpenedPages = createAction(Types.SET_OPENED_PAGES);
export const setActivePage = createAction(Types.SET_ACTIVE_PAGE);
