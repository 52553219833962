import path from '../../utils/pathSelector';
import {ReduxRootStateInterface} from '../../interfaces/Redux';
import {getRequestsByName} from '../../utils/requests';
import {Types as EntitiesActionTypes} from '../../actions/develop/entities';

export const getEntitiesData = path(['entities', 'list']);
export const getFocusEntityProperty = path(['entities', 'focusEntityProperty']);

export const isGetEntitiesLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EntitiesActionTypes.LOAD_DATA);
};

export const isSavePropertiesLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EntitiesActionTypes.SAVE_ENTITY_PROPERTIES) || !!getRequestsByName(inProgressRequests, EntitiesActionTypes.LOAD_DATA);
};

export const getEntityById = (entityId: string) => (state: ReduxRootStateInterface) => {
	const entities = getEntitiesData(state);

	return entities.find(({id}: any) => id === entityId);
};

export const isAddEntityLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EntitiesActionTypes.ADD_DATA) || !!getRequestsByName(inProgressRequests, EntitiesActionTypes.LOAD_DATA);
};

export const isUpdateEntityPropertyLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EntitiesActionTypes.UPDATE_ENTITY_PROPERTY) || !!getRequestsByName(inProgressRequests, EntitiesActionTypes.LOAD_DATA);
};

export const isRemoveEntityPropertyLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EntitiesActionTypes.REMOVE_ENTITY_PROPERTY) || !!getRequestsByName(inProgressRequests, EntitiesActionTypes.LOAD_DATA);
};

export const isUpdateEntityLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EntitiesActionTypes.UPDATE_DATA) || !!getRequestsByName(inProgressRequests, EntitiesActionTypes.LOAD_DATA);
};


export const isDeleteEntityLoading = (state: ReduxRootStateInterface) => {
	const inProgressRequests = path(['requests', 'onGoing'])(state);

	return !!getRequestsByName(inProgressRequests, EntitiesActionTypes.DELETE) || !!getRequestsByName(inProgressRequests, EntitiesActionTypes.LOAD_DATA);
};
