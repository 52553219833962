import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getAll({projectId}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/routers`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addRouter({projectId, body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/routers`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function updateRouter({projectId, routerId, body}: any) {
	return request.patch({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/routers/${routerId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function deleteRouter({projectId, routerId}: any) {
	return request.delete({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/routers/${routerId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

