import React from 'react';
import {connect} from 'react-redux';
import {ReduxRootStateInterface} from '../interfaces/Redux';
import TopLoadingBar from '../components/TopLoadingBar';
import {
	isTopLoadingBarVisible,
} from '../selectors/generic';
import PropTypes from 'prop-types';

function TopLoadingBarContainer(props: any) {
	if (!props.isTopLoadingBarVisible) {
		return null;
	}

	return (
		<TopLoadingBar />
	);
}

TopLoadingBarContainer.propTypes = {
	isTopLoadingBarVisible: PropTypes.bool,
};

export default connect(
	(state: ReduxRootStateInterface) => {
		return {
			isTopLoadingBarVisible: isTopLoadingBarVisible(state),
		};
	},
)(TopLoadingBarContainer);
