import React from 'react';
import {connect} from 'react-redux';
import {ReduxRootStateInterface} from '../interfaces/Redux';
import NotificationBox from '../components/NotificationBox';
import {
	getNotifications,
} from '../selectors/notifications';
import Snackbar from '@material-ui/core/Snackbar';
import omit from '../utils/omit';
import {bindActionCreators, Dispatch} from 'redux';
import * as notificationsActions from '../actions/notifications';

function TopLoadingBarContainer(props: any) {
	const {
		actions,
		notificationsData,
	} = props;

	if (!notificationsData) {
		return null;
	}

	const handleClose = (id: string, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		actions.notifications.remove(id);
	};

	return (
		<>
			{notificationsData.map((notification: any) => (
				<Snackbar
					key={notification.id}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'right',
					}}
					open={true}
					autoHideDuration={notification.autoCloseDuration || 6000}
					onClose={(event, reason) => handleClose(notification.id, reason)}
				>
					<NotificationBox
						onClose={() => handleClose(notification.id)}
						variant={notification.type}
						message={notification.message}
					/>
				</Snackbar>
			))}
		</>
	);
}

export default connect(
	(state: ReduxRootStateInterface) => {
		return {
			notificationsData: getNotifications(state),
		};
	},
	(dispatch: Dispatch) => ({
		actions: {
			notifications: bindActionCreators(omit(notificationsActions, 'Types'), dispatch),
		},
	}),
)(TopLoadingBarContainer);
