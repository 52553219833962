import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getBuildConfig({
	projectId,
	buildId,
}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_PROJECT_URL}/projects/${projectId}/builds/${buildId}/configurations`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
