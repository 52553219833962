import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getProjectStructure({
	projectId,
	buildId,
  compareToBuildId,
	projectName,
}: any) {
	return request({
		uri: `${process.env.REACT_APP_CODE_GENERATOR_BASE_URL}/project-structure/${projectId}/${buildId}/${compareToBuildId ? `${compareToBuildId}/` : ''}${projectName}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function getSourceCode({
	projectId,
	buildId,
  compareToBuildId,
	projectName,
	filePath,
}: any) {
	return request({
		uri: `${process.env.REACT_APP_CODE_GENERATOR_BASE_URL}/file-content/${projectId}/${buildId}${compareToBuildId ? `/${compareToBuildId}` : ''}/${projectName}/${filePath}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
