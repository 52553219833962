import {
	call,
	put,
	takeLatest,
} from 'redux-saga/effects';
import * as sshKeyActions from '../../../actions/account/sshKeys';
import {request} from '../../request';
import * as api from '../../../services/api/account/sshKey';
import * as notificationsActions from '../../../actions/notifications';

function* getSshKeysData() {
	console.log('LOAD SSH DATA!!!!');
	const sshKeyData = yield call(request, {
		entity: sshKeyActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {},
	});
	const body = sshKeyData.payload.body;

	if (body.success) {
		yield put(sshKeyActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* addSshKey({payload}: any) {
	const {
		name,
		key,
		resolve,
		reject,
	} = payload;

	const sshKeyData = yield call(request, {
		entity: sshKeyActions.Types.ADD_DATA,
		callback: api.addSshKey,
		params: {
			body: {
				name,
				key,
			},
		},
	});

	const body = sshKeyData.payload.body;

	if (body.success) {
		yield call(getSshKeysData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* removeSshKey({payload}: any) {
	const {
		values,
		resolve,
		reject,
	} = payload;
	const sshKeyId = values.id;

	const sshKeyData = yield call(request, {
		entity: sshKeyActions.Types.REMOVE_DATA,
		callback: api.deleteSshKey,
		params: {
			sshKeyId,
		},
	});

	const body = sshKeyData.payload.body;

	if (body.success) {
		yield call(getSshKeysData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchGetSshKeyData() {
	yield takeLatest([sshKeyActions.Types.LOAD_DATA], getSshKeysData);
}

export function* watchAddSshKeyData() {
	yield takeLatest([sshKeyActions.Types.ADD_DATA], addSshKey);
}

export function* watchRemoveSshKeyData() {
	yield takeLatest([sshKeyActions.Types.REMOVE_DATA], removeSshKey);
}
