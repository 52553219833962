import {
	call,
	takeLatest,
	put,
} from 'redux-saga/effects';
import * as accountActions from '../../../actions/account';
import * as notificationsActions from '../../../actions/notifications';
import * as api from '../../../services/api/account';
import { request } from '../../request';

function* loginUser(action: any) {
	const loginData = yield call(request, {
		entity: accountActions.Types.LOGIN_USER,
		callback: api.login,
		params: action.payload,
	});

	const payload = loginData.payload.body;

	if (payload.data) {
		localStorage.setItem('ZEMUZ:AUTH_TOKENS', JSON.stringify(payload.data));
		window.location.href = '/'
	} else if (payload.error) {
		yield put(notificationsActions.addError({
			message: payload.error.message || payload.error.name,
		}));
	}
}

export function* watchLoginUser() {
	yield takeLatest(accountActions.Types.LOGIN_USER, loginUser);
}
