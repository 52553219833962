import {createMatchSelector} from 'connected-react-router';
import {ReduxRootStateInterface} from "../interfaces/Redux";

export const getProjectId = (state: ReduxRootStateInterface) => {
	const location = createMatchSelector('/projects/:projectId')(state);

	if (location) {
		const params: any = location.params;

		if (params) {
			return params.projectId;
		}
	}

	return null;
};

export const getEnvironmentId = (state: ReduxRootStateInterface) => {
	const location = createMatchSelector('/projects/:projectId/develop/environments/:environmentId')(state);

	if (location) {
		const params: any = location.params;

		if (params) {
			return params.environmentId;
		}
	}

	return null;
};
