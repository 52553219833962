import {
	call,
	select,
	put,
	takeLatest,
} from 'redux-saga/effects';
import * as databasesActions from '../../actions/databases';
import {
	getProjectId,
} from '../../selectors/router';
import {request} from "../request";
import * as api from "../../services/api/databases";
import * as notificationsActions from "../../actions/notifications";

function* getDatabasesData() {
	const projectId = yield select(getProjectId);

	const databasesData = yield call(request, {
		entity: databasesActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		}
	});
	const body = databasesData.payload.body;

	if (body.success) {
		yield put(databasesActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

export function* softAddDatabase({payload}: any) {
	const {
		values,
		resolve,
		reject
	} = payload;
	let projectId = values.projectId;
	if (!projectId) {
		projectId = yield select(getProjectId);
	}

	const databaseData = yield call(request, {
		entity: databasesActions.Types.ADD_DATA,
		callback: api.addDatabase,
		params: {
			projectId,
			body: values.database,
		}
	});

	const body = databaseData.payload.body;

	if (body.success) {
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}

	return body;
}

export function* addDatabase({payload}: any) {
	const {
		values,
		resolve,
		reject,
	} = payload;
	let projectId = values.projectId;
	if (!projectId) {
		projectId = yield select(getProjectId);
	}

	const databaseData = yield call(request, {
		entity: databasesActions.Types.ADD_DATA,
		callback: api.addDatabase,
		params: {
			projectId,
			body: values.database,
		}
	});

	const body = databaseData.payload.body;

	if (body.success) {
		yield call(getDatabasesData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* removeDatabase({payload}: any) {
	const {
		values,
		resolve,
		reject,
	} = payload;
	let projectId = values.projectId;
	if (!projectId) {
		projectId = yield select(getProjectId);
	}
	const databaseId = values.id;

	const databaseData = yield call(request, {
		entity: databasesActions.Types.REMOVE_DATA,
		callback: api.deleteDatabase,
		params: {
			projectId,
			databaseId,
		}
	});

	const body = databaseData.payload.body;

	if (body.success) {
		yield call(getDatabasesData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchGetDatabasesData() {
	yield takeLatest([databasesActions.Types.LOAD_DATA], getDatabasesData);
}

export function* watchAddDatabaseData() {
	yield takeLatest([databasesActions.Types.ADD_DATA], addDatabase);
}

export function* watchRemoveDatabaseData() {
	yield takeLatest([databasesActions.Types.REMOVE_DATA], removeDatabase);
}
