import { createAction } from 'redux-actions';
import { RequestInterface } from '../interfaces/Request';

export enum Types {
	STARTED = 'REQUEST_STARTED',
	SUCCEEDED = 'REQUEST_SUCCEEDED',
	ERRORED = 'REQUEST_ERRORED',
	CANCELLED = 'REQUEST_CANCELLED',
}

export const start = createAction<RequestInterface>(Types.STARTED);
export const succeeded = createAction<RequestInterface>(Types.SUCCEEDED);
export const errored = createAction<RequestInterface>(Types.ERRORED);
export const canceled = createAction<RequestInterface>(Types.CANCELLED);
