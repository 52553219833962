import React from 'react';
import {Field} from 'redux-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const renderCheckboxComponent = ({
	input,
	label,
  classes,
	...rest
}: any) => (
	<FormControlLabel
		control={
			<Checkbox
				checked={!!input.value}
				onChange={input.onChange}
				{...rest}
			/>
		}
		label={label}
		classes={{
			root: classes && classes.labelRoot,
			label: classes && classes.label,
		}}
	/>
);

export default ({
	name,
	...rest
}: any) => (
	<Field
		name={name}
		component={renderCheckboxComponent}
		{...rest}
	/>
);
