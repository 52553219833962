import {createAction} from 'redux-actions';

export enum Types {
	LOAD_DATA = 'DEPLOY_ENVIRONMENTS_LOAD_DATA',
	LOAD_DATA_RECEIVED = 'DEPLOY_ENVIRONMENTS_LOAD_DATA_RECEIVED',
	UPDATE_DATA = 'DEPLOY_ENVIRONMENTS_UPDATE_DATA',
	DEPLOY = 'DEPLOY_ENVIRONMENTS_DEPLOY',
	SOFT_UPDATE_DATA = 'DEPLOY_ENVIRONMENTS_SOFT_UPDATE_DATA',
	ADD_DATA = 'DEPLOY_ENVIRONMENTS_ADD_DATA',
	DELETE = 'DEPLOY_ENVIRONMENTS_DELETE',
}

export const loadData = createAction(Types.LOAD_DATA);
export const loadDataReceived = createAction(Types.LOAD_DATA_RECEIVED);
export const updateData = createAction(Types.UPDATE_DATA);
export const deploy = createAction(Types.DEPLOY);
export const softUpdate = createAction(Types.SOFT_UPDATE_DATA);
export const addData = createAction(Types.ADD_DATA);
export const deleteEnvironment = createAction(Types.DELETE);
