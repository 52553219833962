import {handleActions} from 'redux-actions';
import {Record, List} from 'immutable';
import {DeveloperReduxStateInterface} from '../../interfaces/settings/Developer';
import * as DevelopersActions from '../../actions/settings/developers';

const initialData: DeveloperReduxStateInterface = {
	list: List(),
};

const DevelopersData = Record(initialData);

export default handleActions<any, any>(
	{
		[DevelopersActions.Types.LOAD_DATA]: (state) => {
			return state
				.set('list', List());
		},
		[DevelopersActions.Types.LOAD_DATA_RECEIVED]: (state, action) => {
			const {
				payload: {
					data,
				},
			} = action;

			return state
				.set('list', List(data));
		},
	},
	new DevelopersData(),
);
