import request, {
	bodyParser,
	getDefaultHeaders,
} from '../../request';

export async function getAll({projectId}: any) {
	return request({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/entities`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}

export async function addEntity({projectId, body}: any) {
	return request.post({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/entities`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function updateEntity({projectId, body, entityId}: any) {
	return request.patch({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/entities/${entityId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
		body: JSON.stringify(body),
	})
		.then(bodyParser);
}

export async function deleteEntity({projectId, entityId}: any) {
	return request.delete({
		uri: `${process.env.REACT_APP_API_BUILDER_URL}/projects/${projectId}/entities/${entityId}`,
		headers: await getDefaultHeaders(),
		resolveWithFullResponse: true,
	})
		.then(bodyParser);
}
