import {
	call,
	put,
	takeLatest,
	select,
} from 'redux-saga/effects';
import * as authorizationsActions from '../../actions/authorizations';
import {request} from "../request";
import * as api from "../../services/api/authorizations";
import * as notificationsActions from "../../actions/notifications";
import {getProjectId} from "../../selectors/router";
import {getDatabasesData} from "../../selectors/databases";
import {generateAuthModels, generateAuthRouters} from "../../utils/authentications";
import * as entitiesActions from "../../actions/develop/entities";
import * as entitiesApi from "../../services/api/develop/entities";
import * as routersActions from "../../actions/develop/routers";
import * as routersApi from "../../services/api/develop/routers";

function* getAuthorizationsData() {
	const projectId = yield select(getProjectId);

	const projectsData = yield call(request, {
		entity: authorizationsActions.Types.LOAD_DATA,
		callback: api.getAll,
		params: {
			projectId,
		}
	});

	const body = projectsData.payload.body;

	if (body.success) {
		yield put(authorizationsActions.loadDataReceived(body));
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
	}
}

function* softAddEntityData({payload}: any) {
	const {
		values: {
			projectId,
			entities,
		},
		resolve,
		reject,
	} = payload;

	const entitiesData = yield call(request, {
		entity: entitiesActions.Types.SOFT_ADD_DATA,
		callback: entitiesApi.addEntity,
		params: {
			projectId,
			body: entities,
		}
	});
	const body = entitiesData.payload.body;

	if (body.success) {
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}

	return body;
}

function* softAddRouterData({payload}: any) {
	const {
		values: {
			projectId,
			routers,
		},
		resolve,
		reject,
	} = payload;

	const routersData = yield call(request, {
		entity: routersActions.Types.SOFT_ADD_DATA,
		callback: routersApi.addRouter,
		params: {
			projectId,
			body: routers,
		}
	});
	const body = routersData.payload.body;

	if (body.success) {
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}

	return body;
}

function* addAuthorization({payload}: any) {
	const {
		values,
		resolve,
		reject,
	} = payload;
	let projectId = values.projectId;
	if (!projectId) {
		projectId = yield select(getProjectId);
	}
	const databases = yield select(getDatabasesData);

	const authorizationData = yield call(request, {
		entity: authorizationsActions.Types.ADD_DATA,
		callback: api.addAuthorization,
		params: {
			projectId,
			body: values.authorization,
		}
	});

	const {id} = databases.get(0);
	const body = authorizationData.payload.body;
	if (body.success) {
		const entities = generateAuthModels([body.data], id);

		if (entities && entities.length) {
			const entitiesData = yield call(softAddEntityData, {
				payload: {
					values: {
						projectId,
						entities,
					},
					resolve: () => {},
				},
			});

			if (entitiesData.success) {
				const entitiesIds = entitiesData.data.map(({ data: { id, properties }, success }:any) => {
					if (!success) {
						return [null, null];
					}

					return [id, properties[0].id];
				});

				const routers = generateAuthRouters([body.data], entitiesIds);

				yield call(softAddRouterData, {
					payload: {
						values: {
							projectId,
							routers,
						},
						resolve: () => {},
					},
				});
			}
		}

		yield call(getAuthorizationsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* updateAuthorization({payload}: any) {
	const {
		values,
		resolve,
		reject,
	} = payload;
	let projectId = values.projectId;
	if (!projectId) {
		projectId = yield select(getProjectId);
	}
	const { authorization } = values;

	const authorizationData = yield call(request, {
		entity: authorizationsActions.Types.UPDATE_DATA,
		callback: api.updateAuthrization,
		params: {
			projectId,
			authorizationId: authorization.id,
			body: authorization,
		}
	});

	const body = authorizationData.payload.body;

	if (body.success) {
		yield call(getAuthorizationsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

function* removeAuthorization({payload}: any) {
	const {
		values,
		resolve,
		reject,
	} = payload;
	let projectId = values.projectId;
	if (!projectId) {
		projectId = yield select(getProjectId);
	}
	const authorizationId = values.id;

	const databaseData = yield call(request, {
		entity: authorizationsActions.Types.REMOVE_DATA,
		callback: api.deleteAuhtorization,
		params: {
			projectId,
			authorizationId,
		}
	});

	const body = databaseData.payload.body;

	if (body.success) {
		yield call(getAuthorizationsData);
		resolve && resolve();
	} else {
		yield put(notificationsActions.addError({
			message: body.error.message || body.error.name,
		}));
		reject && reject();
	}
}

export function* watchGetAuthorizationsData() {
	yield takeLatest([authorizationsActions.Types.LOAD_DATA], getAuthorizationsData);
}

export function* watchAddAuthorizations() {
	yield takeLatest([authorizationsActions.Types.ADD_DATA], addAuthorization);
}

export function* watchUpdateAuthorization() {
	yield takeLatest([authorizationsActions.Types.UPDATE_DATA], updateAuthorization);
}

export function* watchRemoveAuthorizations() {
	yield takeLatest([authorizationsActions.Types.REMOVE_DATA], removeAuthorization);
}
